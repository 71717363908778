<div id="main_page">
  <app-ai-chat
    title="Chat with AI"
    firstMessage="Hey there! I’m here to help you with any questions about UAE"
    [isClosed]="true"></app-ai-chat>

  <div class="header">
    <div class="title-block">
      <h1 class="title">Riyado Travel Planner</h1>
      <h3 class="subtitle">Your Personal Travel Guide in UAE</h3>
    </div>

    <div class="search-block">
      <div class="input-block">
        <div class="icon-block">
          <ng-icon name="matSearchOutline"></ng-icon>
        </div>

        <app-autocomplete-input
          id="search_input"
          placeholder="What are you looking for?"
          #autocompleteInput></app-autocomplete-input>

        <a
          class="button"
          routerLink="/search"
          [queryParams]="{ input: autocompleteInput.value }">
          <div class="button-label">Search</div>
        </a>
      </div>
    </div>

    <div class="activities-list">
      <a
        class="activity"
        routerLink="/places"
        [queryParams]="{ activities: [activity.name] }"
        *ngFor="let activity of placeActivities">
        <ng-icon [name]="activity.icon"></ng-icon>
        <div class="activity-name">{{ activity.name }}</div>
      </a>

      <a class="activity see-all" routerLink="/places">
        <ng-icon name="matArrowForwardOutline"></ng-icon>
        <div class="activity-name">See all</div>
      </a>
    </div>
  </div>

  <div class="content-wrap">
    <div class="ai-content">
      <div class="text">
        <div class="subtitle">Powered by AI <span>BETA</span></div>
        <h2 class="title">Discover UAE with Our AI Assistant</h2>
        <div class="prompt">
          Ask our AI chat guide for personalized recommendations and travel
          tips!
        </div>

        <div class="button-block">
          <div class="ai-button" (click)="openAiChat()">
            <ng-icon name="ionSparklesSharp"></ng-icon>
            Speak with AI
          </div>

          <div class="metrics-content" *ngIf="metrics">
            <div class="metric">
              <ng-icon name="matPlaceOutline"></ng-icon>
              <div class="title">
                <span>{{ shortNumber(metrics.placesCount) }}</span>
                places
              </div>
            </div>

            <div class="metric">
              <ng-icon name="matRouteOutline"></ng-icon>
              <div class="title">
                <span>{{ shortNumber(metrics.routesCount, 10) }}</span>
                routes
              </div>
            </div>

            <div class="metric">
              <ng-icon name="matExploreOutline"></ng-icon>
              <div class="title">
                <span>{{ shortNumber(metrics.regionsCount, 10) }}</span>
                regions
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="image">
        <img
          ngSrc="/assets/ai-background.webp"
          width="674"
          height="450"
          alt="AI" />
      </div>
    </div>

    <div class="main-page-block">
      <app-entities-preview-list
        title="Explore UAE"
        subtitle="Find the most interesting places to visit"
        routerLinkValue="/places"
        [nowrap]="true"
        [places]="places"></app-entities-preview-list>
    </div>

    <div class="main-page-block">
      <app-entities-preview-list
        title="Browse by region"
        subtitle="Find the best spots in each area"
        routerLinkValue="/regions"
        [regions]="regions"></app-entities-preview-list>
    </div>

    <div class="main-page-block">
      <app-entities-preview-list
        title="Explore Our Activities"
        subtitle="Engage, Discover, and Enjoy Something New Every Day"
        routerLinkValue="/activities"
        [nowrap]="true"
        [activities]="activities"></app-entities-preview-list>
    </div>

    <div class="main-page-block">
      <app-entities-preview-list
        title="Seeking Challenges and Adventures"
        subtitle="Explore New Challenges and Exciting Adventures in UAE"
        routerLinkValue="/routes"
        [nowrap]="true"
        [routes]="routes"></app-entities-preview-list>
    </div>

    <div class="main-page-block">
      <app-entities-preview-list
        title="Our collections of wonderful spots"
        subtitle="Discover Hidden Gems and Thrilling Places in Our UAE Collections"
        routerLinkValue="/collections"
        [nowrap]="true"
        [collections]="placeCollections"></app-entities-preview-list>
    </div>

    <div class="photo-spots-block main-page-block">
      <app-entities-preview-list
        title="Take a wonderful photos"
        subtitle="The best photo spots in UAE"
        routerLinkValue="/places"
        [nowrap]="true"
        [queryParams]="{ activities: ['Photo'] }"
        [places]="photoSpots"></app-entities-preview-list>
    </div>
  </div>
</div>
