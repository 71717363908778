<div id="privacy">
  <div class="content-wrap">
    <h3>Privacy Policy</h3>

    <p>
      Welcome to riyado.com! This Privacy Policy, along with our Cookie Policy
      and Terms and Conditions, explains how Lusora LLC manages the collection
      and use of your personal information when you visit our website or use our
      services.
    </p>

    <h3>About This Privacy Policy and riyado.com</h3>

    <p>
      We recognize that providing your information online requires a high level
      of trust. We take this responsibility seriously and prioritize the
      security and confidentiality of your personal information. Please read
      this Privacy Policy carefully to understand our practices.
    </p>

    <h3>When Does This Privacy Policy Apply?</h3>

    <p>
      This Privacy Policy applies to identifiable information that riyado.com
      collects from or about you. It does not apply to anonymous data that
      cannot identify you. Our site may contain links to third-party websites;
      we are not responsible for their privacy practices. We recommend reviewing
      their privacy policies.
    </p>

    <h3>Data Controller</h3>

    <p>
      The data controller for any information provided to or collected by
      riyado.com is Lusora LLC, located at Shams Business Center, Sharjah Media
      City Free Zone, Al Messaned, Sharjah, UAE.
    </p>

    <h3>About Your Personal Information and Uses</h3>

    <h4>What Personal Information Does riyado.com Collect?</h4>

    <h5>Information You Provide Us Directly</h5>

    <p>We collect and use information you provide directly to us, such as:</p>

    <ul>
      <li>
        Name, gender, date of birth, mobile number, email address, and other
        contact details when you register an account or book services.
      </li>
      <li>
        Payment details such as bank card number, credit card number, cardholder
        name, and expiry date when purchasing travel products.
      </li>
      <li>
        Travel preferences, including meal requirements, departure date,
        check-in/check-out times, seat choice, ticket choice, and other service
        preferences.
      </li>
    </ul>

    <p>
      If you contact our customer service or participate in referral programs or
      sweepstakes, we will collect additional information from you.
    </p>

    <h5>Information We Collect Automatically</h5>

    <p>We automatically collect information such as:</p>

    <ul>
      <li>
        Device and software information, including IP address, software version,
        and device identifier.
      </li>
      <li>
        Information on web pages visited, search terms, and content shared
        through our services.
      </li>
      <li>Location information when you enable location-based services.</li>
    </ul>

    <h5>Information We Receive Through Other Sources</h5>
    <p>
      We may also obtain your information from affiliates and business partners,
      or through social media login.
    </p>
    <h5>Special Categories of Personal Information</h5>

    <p>
      We may collect special category information such as disability
      considerations and dietary preferences to fulfill your travel requests.
    </p>

    <h3>How Does riyado.com Use My Personal Information?</h3>

    <p>We use your personal information for:</p>

    <ul>
      <li>Providing services and managing bookings.</li>
      <li>Account management.</li>
      <li>Responding to inquiries and requests.</li>
      <li>Marketing activities.</li>
      <li>Contacting you for feedback or service improvements.</li>
      <li>Market research.</li>
      <li>Improving service security and reliability.</li>
      <li>Data analysis.</li>
      <li>Daily operations.</li>
      <li>Monitoring for fraud prevention.</li>
      <li>Displaying reviews and feedback.</li>
      <li>Payment verification.</li>
      <li>Investigations and complaints management.</li>
      <li>Legal compliance.</li>
    </ul>

    <h3>How Does riyado.com Share My Information?</h3>
    <p>We may share your information with:</p>

    <ul>
      <li>
        Service providers such as hotels, airlines, and car rental agencies.
      </li>
      <li>
        Third-party vendors for services like credit card processing and
        customer service.
      </li>
      <li>Financial institutions and third-party payment agencies.</li>
      <li>Business partners offering products or services on our site.</li>
      <li>Affiliated companies within our corporate family.</li>
    </ul>
    <p>
      We may also share information to comply with legal obligations or in the
      case of a merger or acquisition.
    </p>

    <h3>Modification of Privacy Policy</h3>
    <p>
      We may update this Privacy Policy as necessary. The latest update will be
      noted, and changes will take effect when published. We encourage you to
      review this policy periodically.
    </p>

    <h3>Cookie Policy</h3>

    <h4>What is a cookie?</h4>

    <p>
      A cookie is a small text file stored on your computer when you use our
      website. Learn more at www.aboutcookies.org.
    </p>

    <h4>What cookies does riyado.com use?</h4>

    <ul>
      <li>
        Essential cookies: Necessary for website operation, such as logging in
        and using the shopping cart.
      </li>
      <li>
        Non-essential cookies:
        <ul>
          <li>
            Functionality cookies: Recognize you when you return and remember
            your preferences.
          </li>
          <li>
            Analytical and performance cookies: Help us improve the website by
            tracking visitor numbers and movements.
          </li>
          <li>
            Targeting cookies: Record your visits and interests to make our
            website more relevant to you.
          </li>
        </ul>
      </li>
    </ul>

    <p>
      We use session cookies (deleted when you close your browser) and
      persistent cookies (remain on your computer for future visits).
    </p>
  </div>
</div>
