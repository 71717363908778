import { Component, OnInit, ViewChild } from '@angular/core';
import {
  RegionDto,
  RegionsApiService,
  RoutePreviewDto,
  RoutesApiService,
} from '../../core/api/generated/riyado-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { orderBy } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { routeTypes } from '../route.types';
import { PlacesMapComponent } from '../../core/places-map/places-map.component';
import { FilterCheckboxOption } from '../../core/filter-checkbox/filter-checkbox.component';
import { formatDistance, formatMinutes } from '../../places/places.utils';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject, takeUntil } from 'rxjs';
import { ListPopoverItem } from '../../core/custom-select/custom-select.component';
import { SearchRoutes$Params } from '../../core/api/generated/riyado-api/fn/routes/search-routes';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

dayjs.extend(utc);

@Component({
  selector: 'app-routes',
  styleUrls: ['routes-list-page.component.scss'],
  templateUrl: 'routes-list-page.component.html',
})
export class RoutesListPageComponent implements OnInit {
  public routes?: RoutePreviewDto[];
  public regions: RegionDto[] = [];

  public totalItems = 0;
  public totalFilteredItems = 0;

  public page = 1;
  public limit = 25;
  public isLoading = false;
  public isMapLoading = false;
  public isMapView = false;
  public isFiltersOpen = false;
  public isHorizontal = true;

  public sortBy?: SearchRoutes$Params['sortBy'];

  @ViewChild(PlacesMapComponent)
  public map: PlacesMapComponent;

  constructor(
    private readonly routesApiService: RoutesApiService,
    private readonly regionsApiService: RegionsApiService,
    private route: ActivatedRoute,
    private readonly breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.generatePageMeta();
  }

  private generatePageMeta() {
    const title = 'Riyado - Explore Routes';
    const description =
      'Discover the best routes for exploring the UAE with Riyado. Whether you’re planning a scenic road trip through Dubai and Abu Dhabi or an adventurous journey across the emirates, find the perfect routes to suit your travel style.';
    const keywords =
      'Riyado explore routes UAE, UAE road trips, scenic routes UAE, Dubai routes, Abu Dhabi routes, travel routes UAE, adventure routes UAE, Riyado travel guide, explore UAE, popular emirates, journey across UAE, Riyado service';

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaService.updateTag({
      name: 'og:description',
      content: description,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: `${environment.dashboardUrl}/assets/logo.png`,
    });

    this.metaService.updateTag({
      name: 'keywords',
      content: keywords,
    });
  }

  public updateSortBy(val: ListPopoverItem) {
    this.sortBy = <SearchRoutes$Params['sortBy']>val.value;
    this.onFiltersChange();
  }

  public closeFilters(e: MouseEvent) {
    const barBody = document.querySelector('#filters .filters-body');
    const clickedInside = barBody?.contains(e.target as Node);

    if (!clickedInside) {
      this.isFiltersOpen = false;
    }
  }

  public toggleMapView(isMapView: boolean) {
    if (isMapView === this.isMapView) {
      return;
    }

    this.isMapView = isMapView;

    setTimeout(() => {
      if (this.isMapView) {
        this.updateMapRoutes();
      } else {
        this.map?.cleanMap();
        this.updateRoutes();
      }
    }, 0);
  }

  public typesSelectOptions: FilterCheckboxOption[] = orderBy(
    Object.entries(routeTypes).map(([i, val]) => ({
      label: val.name,
      value: i,
    })),
    'label',
    'asc',
  );
  public typesFilter: string[] = [];

  public regionsFilterOptions: FilterCheckboxOption[] = [];
  public regionsFilter: number[] = [];

  public durationFilter: number[] = [];
  public distanceFilter: number[] = [];

  private destroy$ = new Subject<void>();

  ngOnInit() {
    const initialValue =
      this.breakpointObserver.isMatched('(max-width: 820px)');
    this.isHorizontal = !initialValue;

    this.updateFiltersData();
    this.parseQueryParams();
    this.updateRoutes();

    this.breakpointObserver
      .observe('(max-width: 820px)')
      .subscribe((result) => {
        this.isHorizontal = !result.matches;
      });
  }

  public parseQueryParams() {
    const params = this.route.snapshot.queryParamMap;
    const types = params.get('types')?.split(',') || [];

    if (types.length > 0) {
      this.typesFilter = <any>types;
    }

    const regions = params.get('regions')?.split(',') || [];

    if (regions.length > 0) {
      this.regionsFilter = regions.map(Number);
    }

    const page = parseInt(String(params.get('page')));
    if (page) {
      this.page = page;
    }
  }

  public routeTrackBy(index: number, route: RoutePreviewDto) {
    return route.id;
  }

  public onPageChanged(page: number) {
    this.page = page;

    this.updateRoutes();
  }

  public formatDuration(val: number) {
    return formatMinutes(val);
  }

  public updateDurationFilter(value: number[]) {
    this.durationFilter = value;
    this.onFiltersChange();
  }

  public formatDistance(val: number) {
    return formatDistance(val);
  }

  public updateDistanceFilter(value: number[]) {
    this.distanceFilter = value;
    this.onFiltersChange();
  }

  public onFiltersChange() {
    this.page = 1;
    //this.routes = undefined;

    setTimeout(() => {
      if (this.isMapView) {
        this.updateMapRoutes();
      } else {
        this.updateRoutes();
      }
    }, 0);
  }

  public updateFiltersData() {
    this.regionsApiService.getRegions().subscribe((regions) => {
      this.regions = regions;

      this.regionsFilterOptions = this.regions.map((r) => ({
        label: r.name,
        value: r.id,
      }));
    });
  }

  public get totalPages() {
    return Math.ceil(this.totalFilteredItems / this.limit) || 1;
  }

  public updateRoutes() {
    this.isLoading = true;

    this.destroy$.next();

    this.routesApiService
      .searchRoutesPreview({
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        types: <any>this.typesFilter,
        regionIds: this.regionsFilter,
        minMaxDuration: this.durationFilter,
        distanceMax: this.distanceFilter[0],
        sortBy: this.sortBy,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.routes = data.items;
          this.totalItems = data.totalItems;
          this.totalFilteredItems = data.totalFilteredItems;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  public updateMapRoutes() {
    this.isMapLoading = true;

    this.routesApiService
      .searchRoutesPreview({
        types: <any>this.typesFilter,
        regionIds: this.regionsFilter,
        minMaxDuration: this.durationFilter,
        distanceMax: this.distanceFilter[0],
      })
      .subscribe({
        next: (data) => {
          this.totalItems = data.totalItems;
          this.totalFilteredItems = data.totalFilteredItems;

          this.map?.renderRoutes(data.items.map((p) => ({ data: p })) || []);
          this.isMapLoading = false;
        },
        error: () => {
          this.isMapLoading = false;
        },
      });
  }
}
