import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pagination',
  templateUrl: 'pagination.component.html',
  styleUrl: 'pagination.component.scss',
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input()
  public totalPages: number;

  @Input()
  public autoScroll: boolean = true;

  @Input()
  public addQueryParameter: boolean = true;

  @Input()
  public currentPage: number;

  @Output()
  public pageChanged = new EventEmitter<number>();

  @Input()
  public isLoading?: boolean;

  public paginationArray: (number | '...')[] = [];

  constructor(
    private readonly router: Router,
    private route: ActivatedRoute,
  ) {}

  public scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  public setPage(page: number) {
    if (this.currentPage === page) {
      return;
    }
    this.currentPage = page;
    this.pageChanged.emit(page);
    this.generatePaginationArray();

    if (this.autoScroll) {
      this.scrollToTop();
    }

    if (this.addQueryParameter) {
      this.router.navigate([], {
        queryParams: {
          page: this.currentPage,
        },
        relativeTo: this.route,
        queryParamsHandling: 'merge',
      });
    }
  }

  public nextPage() {
    const next = this.currentPage + 1;

    if (next <= this.totalPages) {
      this.setPage(next);
    }
  }

  public previousPage() {
    const previous = this.currentPage - 1;

    if (previous >= 1) {
      this.setPage(previous);
    }
  }

  ngOnInit() {
    this.generatePaginationArray();
  }

  ngOnChanges() {
    this.generatePaginationArray();
  }

  generatePaginationArray() {
    const delta = 2; // Number of pages to show around the current page
    const range: number[] = [];
    const rangeWithDots: (number | '...')[] = [];
    let l: number | undefined;

    range.push(1); // Always include the first page

    // Generate range around current page
    for (let i = this.currentPage - delta; i <= this.currentPage + delta; i++) {
      if (i < this.totalPages && i > 1) {
        range.push(i);
      }
    }

    if (this.totalPages > 1) {
      range.push(this.totalPages); // Always include the last page
    }

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    this.paginationArray = rangeWithDots;
  }
}
