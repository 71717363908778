<div id="place_collection">
  <div
    *ngIf="collection"
    class="collection-header"
    [style.background]="
      'url(' +
      getMainPictureUrl([collection.file!], 'medium') +
      ') no-repeat center'
    ">
    <div class="content-wrap">
      <h1 class="title">{{ collection.name }}</h1>
    </div>
  </div>

  <div class="content-wrap" *ngIf="collection">
    <div class="description-block" [class.open]="isDescriptionOpen">
      <div class="html-content" [innerHTML]="collection.description"></div>

      <div class="show-all-button" (click)="isDescriptionOpen = true">
        Read more <ng-icon name="matKeyboardArrowDownOutline"></ng-icon>
      </div>
    </div>

    <div class="collection-places">
      <app-entities-preview-list
        [places]="places"
        [newTab]="true"></app-entities-preview-list>
    </div>

    <div class="more-collections">
      <app-entities-preview-list
        title="Explore more collections"
        subtitle="Discover Hidden Gems and Thrilling Places in Our UAE Collections"
        routerLinkValue="/collections"
        [nowrap]="true"
        [newTab]="true"
        [collections]="collections"></app-entities-preview-list>
    </div>
  </div>

  <div class="content-wrap" *ngIf="!collection">
    <app-loader
      [animation]="false"
      width="200px"
      height="200px"
      marginTop="30px"></app-loader>
  </div>
</div>
