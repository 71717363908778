<div class="places-map">
  <div class="loading" *ngIf="loading">
    <div class="loading-animation"></div>
  </div>
  <app-mapbox
    [geocoderEnabled]="false"
    [style]="'streets-v12'"
    [zoom]="13"
    [coordinates]="center || null"
    [addStartPoint]="false"
    #map></app-mapbox>

  <a
    class="google-map-link"
    [href]="googleMapLink"
    target="_blank"
    *ngIf="googleMapLink">
    <img
      ngSrc="/assets/google-icon.png"
      [width]="20"
      [height]="20"
      alt="Google map" />
    Open in google map</a
  >
</div>
