/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RouteDto } from '../../models/route-dto';

export interface GetRoutes$Params {
  offset?: number;
  limit?: number;

/**
 * RoadTrip = 1; Hiking = 2; CityTour = 3
 */
  types?: Array<'RoadTrip' | 'Hiking' | 'CityTour'>;
  minMaxDuration?: Array<number>;
  ids?: Array<number>;
  indexes?: Array<string>;
  regionIds?: Array<number>;
  distanceMax?: number;
  sortBy?: 'Random' | 'DistanceAsc' | 'DistanceDesc' | 'DurationAsc' | 'DurationDesc';
}

export function getRoutes(http: HttpClient, rootUrl: string, params?: GetRoutes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RouteDto>>> {
  const rb = new RequestBuilder(rootUrl, getRoutes.PATH, 'get');
  if (params) {
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
    rb.query('types', params.types, {});
    rb.query('minMaxDuration', params.minMaxDuration, {});
    rb.query('ids', params.ids, {});
    rb.query('indexes', params.indexes, {});
    rb.query('regionIds', params.regionIds, {});
    rb.query('distanceMax', params.distanceMax, {});
    rb.query('sortBy', params.sortBy, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<RouteDto>>;
    })
  );
}

getRoutes.PATH = '/api/v1/routes';
