import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule } from './core/api/api.module';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NotFoundComponent } from './misc/not-found/not-found.component';
import { PlaceFullPageComponent } from './places/place-full-page/place-full-page.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgIconsModule } from '@ng-icons/core';
import {
  matAirOutline,
  matArrowForwardOutline,
  matAttachMoneyOutline,
  matBoltOutline,
  matCallOutline,
  matChevronLeftOutline,
  matChevronRightOutline,
  matCloseOutline,
  matDirectionsBikeOutline,
  matDirectionsCarOutline,
  matExpandLessOutline,
  matExpandMoreOutline,
  matExploreOutline,
  matFamilyRestroomOutline,
  matFilterAltOutline,
  matFreeCancellationOutline,
  matHikingOutline,
  matHolidayVillageOutline,
  matHourglassTopOutline,
  matHttpOutline,
  matKeyboardArrowDownOutline,
  matKeyboardArrowUpOutline,
  matLanguageOutline,
  matListOutline,
  matLiveTvOutline,
  matLocalMallOutline,
  matLocationCityOutline,
  matLocationOnOutline,
  matMapOutline,
  matMuseumOutline,
  matNaturePeopleOutline,
  matNavigateBeforeOutline,
  matNavigateNextOutline,
  matNordicWalkingOutline,
  matPaidOutline,
  matPhotoCameraOutline,
  matPinDropOutline,
  matPlaceOutline,
  matPoolOutline,
  matRouteOutline,
  matScheduleOutline,
  matSearchOutline,
  matSendOutline,
  matShareOutline,
  matSnowboardingOutline,
  matStarOutline,
  matStarRateOutline,
  matSwapVertOutline,
  matThunderstormOutline,
  matVisibilityOutline,
  matWaterDropOutline,
} from '@ng-icons/material-icons/outline';
import { ionSparklesSharp } from '@ng-icons/ionicons';
import {
  bootstrapBuildingsFill,
  bootstrapCalendar,
  bootstrapCarFrontFill,
  bootstrapChatDotsFill,
  bootstrapCheck2Circle,
  bootstrapCheckCircleFill,
  bootstrapCloudRainFill,
  bootstrapCurrencyExchange,
  bootstrapDashCircle,
  bootstrapDashCircleFill,
  bootstrapDot,
  bootstrapGlobe,
  bootstrapList,
  bootstrapPeople,
  bootstrapPerson,
  bootstrapPersonWalking,
  bootstrapPlusCircle,
  bootstrapShare,
  bootstrapSortDown,
  bootstrapTreeFill,
  bootstrapWater,
} from '@ng-icons/bootstrap-icons';
import { TripadvisorRatingComponent } from './core/tripadvisor/tripadvisor-rating.component';
import { GalleryComponent } from './core/gallery/gallery.component';
import { MapboxModule } from './core/mapbox/mapbox.module';
import { WeatherComponent } from './core/weather/weather.component';
import { CustomSelectComponent } from './core/custom-select/custom-select.component';
import { PlacesListPageComponent } from './places/places-list-page/places-list-page.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { FilterSelectComponent } from './core/filter-select/filter-select.component';
import { RoutesListPageComponent } from './routes/routes-list-page/routes-list-page.component';
import { RouteFullPageComponent } from './routes/route-full-page/route-full-page.component';
import { RegionComponent } from './region/region-full-page/region.component';
import { MainComponent } from './main/main.component';
import { PlacePreviewComponent } from './places/place-preview/place-preview.component';
import { PlaceNearByComponent } from './places/place-near-by/place-near-by.component';
import { RoutePreviewComponent } from './routes/route-preview/route-preview.component';
import { RouteNearByComponent } from './routes/route-near-by/route-near-by.component';
import { PlaceCollectionsPageComponent } from './places/place-collections-page/place-collections-page.component';
import { SearchPageComponent } from './search/search-page.component';
import { AiChatComponent } from './core/ai-chat/ai-chat.component';
import { PrivacyPageComponent } from './legal/privacy/privacy-page.component';
import { TermsPageComponent } from './legal/terms/terms-page.component';
import { BreadcrumbComponent } from './core/breadcrumb/breadcrumb.component';
import { PlacesMapComponent } from './core/places-map/places-map.component';
import { PlaceCollectionFullPageComponent } from './places/place-collection-full-page/place-collection-full-page.component';
import { FilterCheckboxComponent } from './core/filter-checkbox/filter-checkbox.component';
import { PaginationComponent } from './core/pagination/pagination.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AutocompleteInputComponent } from './core/autocomplete-input/autocomplete-input.component';
import { ShareButtons } from 'ngx-sharebuttons/buttons';
import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { PlaceCollectionPreviewComponent } from './places/place-collection-preview/place-collection-preview.component';
import { CurrencyManager } from './core/currency-manager';
import { RegionsListComponent } from './region/regions-page/regions.component';
import { ShareComponent } from './core/share/share.component';
import { OutsideClickDirective } from './core/directives/outside-click.derictive';
import { EntitiesPreviewListComponent } from './core/entities-preview-list/entities-preview-list.component';
import { matMoneyOff } from '@ng-icons/material-icons/baseline';
import { GoogleRatingComponent } from './core/google-rating/google-rating.component';
import { ActivityPreviewComponent } from './activities/activity-preview/activity-preview.component';
import { ActivitiesListPageComponent } from './activities/activities-list-page/activities-list-page.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FilterSliderComponent } from './core/filter-slider/filter-slider.component';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { LoaderComponent } from './core/loader/loader.component';
import { ActivityFullPageComponent } from './activities/activity-full-page/activity-full-page.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { ActivityCheckAvailabilityComponent } from './activities/activity-check-availability/activity-check-availability.component';
import { NgForTrackByIdDirective } from './core/directives/track-by.derictive';
import { letsFlagFinish } from '@ng-icons/lets-icons/regular';
import { SuggestionComponent } from './core/suggestion/suggestion.component';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

@NgModule({
  declarations: [
    SuggestionComponent,
    AppComponent,
    NotFoundComponent,
    PlaceFullPageComponent,
    TripadvisorRatingComponent,
    GalleryComponent,
    WeatherComponent,
    CustomSelectComponent,
    PlacesListPageComponent,
    FilterSelectComponent,
    RoutesListPageComponent,
    RouteFullPageComponent,
    RegionComponent,
    MainComponent,
    PlacePreviewComponent,
    PlaceNearByComponent,
    RoutePreviewComponent,
    RouteNearByComponent,
    PlaceCollectionsPageComponent,
    SearchPageComponent,
    AiChatComponent,
    PrivacyPageComponent,
    TermsPageComponent,
    BreadcrumbComponent,
    PlacesMapComponent,
    PlaceCollectionFullPageComponent,
    FilterCheckboxComponent,
    PaginationComponent,
    AutocompleteInputComponent,
    PlaceCollectionPreviewComponent,
    RegionsListComponent,
    ShareComponent,
    OutsideClickDirective,
    NgForTrackByIdDirective,
    EntitiesPreviewListComponent,
    GoogleRatingComponent,
    ActivityPreviewComponent,
    ActivitiesListPageComponent,
    FilterSliderComponent,
    LoaderComponent,
    ActivityFullPageComponent,
    ActivityCheckAvailabilityComponent,
  ],
  imports: [
    NgIconsModule.withIcons({
      matMuseumOutline,
      bootstrapGlobe,
      matLocalMallOutline,
      matLiveTvOutline,
      matNaturePeopleOutline,
      matPoolOutline,
      matFamilyRestroomOutline,
      matHikingOutline,
      matDirectionsBikeOutline,
      matHolidayVillageOutline,
      matSnowboardingOutline,
      matScheduleOutline,
      matExpandMoreOutline,
      matExpandLessOutline,
      matHttpOutline,
      matLanguageOutline,
      matCallOutline,
      matPaidOutline,
      matHourglassTopOutline,
      matStarOutline,
      matWaterDropOutline,
      matAirOutline,
      matThunderstormOutline,
      matVisibilityOutline,
      matChevronRightOutline,
      matChevronLeftOutline,
      matAttachMoneyOutline,
      matSwapVertOutline,
      matNavigateBeforeOutline,
      matNavigateNextOutline,
      matMapOutline,
      matListOutline,
      matPhotoCameraOutline,
      matDirectionsCarOutline,
      matNordicWalkingOutline,
      matLocationCityOutline,
      matRouteOutline,
      matLocationOnOutline,
      matPinDropOutline,
      matStarRateOutline,
      matSearchOutline,
      ionSparklesSharp,
      matCloseOutline,
      matSendOutline,
      bootstrapBuildingsFill,
      matPlaceOutline,
      matExploreOutline,
      matArrowForwardOutline,
      matKeyboardArrowDownOutline,
      matKeyboardArrowUpOutline,
      matShareOutline,
      matBoltOutline,
      bootstrapCurrencyExchange,
      bootstrapShare,
      matMoneyOff,
      matFreeCancellationOutline,
      bootstrapCarFrontFill,
      bootstrapCheck2Circle,
      bootstrapDashCircle,
      bootstrapDot,
      bootstrapPerson,
      bootstrapCalendar,
      bootstrapCheckCircleFill,
      bootstrapPlusCircle,
      letsFlagFinish,
      bootstrapCloudRainFill,
      bootstrapPeople,
      bootstrapDashCircleFill,
      bootstrapPersonWalking,
      bootstrapTreeFill,
      bootstrapWater,
      bootstrapChatDotsFill,
      bootstrapList,
      matFilterAltOutline,
      bootstrapSortDown,
    }),
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    ApiModule,
    HttpClientModule,
    NgOptimizedImage,
    NgxSkeletonLoaderModule,
    MapboxModule,
    MultiSelectModule,
    FormsModule,
    ChipModule,
    AutocompleteLibModule,
    ShareButtons,
    NgxSliderModule,
    LottieComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  providers: [
    // {
    //   provide: REQUEST,
    //   useValue: {}, // Placeholder; the real value is set by the Express engine
    // },
    provideShareButtonsOptions(shareIcons()),
    provideClientHydration(),
    CurrencyManager,
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideMomentDateAdapter({
      parse: {
        dateInput: 'LL',
      },
      display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    }),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
