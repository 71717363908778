import { Component } from '@angular/core';
import dayjs from 'dayjs';
import { placeActivities } from './places/places.types';
import { CurrencyManager } from './core/currency-manager';
import { ApiHelper } from './core/api/api.helper';

dayjs.locale('en');

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class AppComponent {
  public menu = [
    {
      name: 'Explore places',
      routerLink: '/places',
      queryParams: {},
    },
    {
      name: 'Activities',
      routerLink: '/activities',
      queryParams: {},
    },
    {
      name: 'Discover routes',
      routerLink: '/routes',
      queryParams: {},
    },
    {
      name: 'Collections',
      routerLink: '/collections',
      queryParams: {},
    },
    // {
    //   name: 'Photo spots',
    //   routerLink: '/places',
    //   queryParams: {
    //     activities: ['Photo'],
    //   },
    // },
    // {
    //   name: 'Iconic buildings',
    //   routerLink: '/places',
    //   queryParams: {
    //     activities: ['IconicBuilding'],
    //   },
    // },
    // {
    //   name: 'City tours',
    //   routerLink: '/routes',
    //   queryParams: {
    //     types: ['CityTour'],
    //   },
    // },
    // {
    //   name: 'Road trips',
    //   routerLink: '/routes',
    //   queryParams: {
    //     types: ['RoadTrip'],
    //   },
    // },
    // {
    //   name: 'Hiking',
    //   routerLink: '/routes',
    //   queryParams: {
    //     types: ['Hiking'],
    //   },
    // },
  ];

  public menuNavBar = [
    {
      name: 'Main',
      routerLink: '/',
      queryParams: {},
    },
    ...this.menu,
    {
      name: 'Regions',
      routerLink: '/regions',
      queryParams: {},
    },
  ];

  protected readonly placeActivities = Object.entries(placeActivities);

  public isSearchOpen = false;
  public isMenuOpen = false;

  constructor(
    public currencyManager: CurrencyManager,
    public apiHelper: ApiHelper,
  ) {}

  public closeMenu(e: MouseEvent) {
    const barBody = document.querySelector('#nav_bar .bar-body');
    const clickedInside = barBody?.contains(e.target as Node);

    if (!clickedInside) {
      this.isMenuOpen = false;
    }
  }
}
