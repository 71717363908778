import { Component, Input } from '@angular/core';
import { ActivityPreviewDto } from '../../core/api/generated/riyado-api';
import { CurrencyManager } from '../../core/currency-manager';
import { formatActivityDuration } from '../../places/places.utils';
import {
  getActivityDestinations,
  isActivityPickupIncluded,
} from '../activities.utils';

@Component({
  selector: 'app-activity-preview',
  styleUrls: ['activity-preview.component.scss'],
  templateUrl: 'activity-preview.component.html',
})
export class ActivityPreviewComponent {
  @Input()
  public activity: ActivityPreviewDto;

  @Input()
  public horizontal = false;

  @Input()
  public newTab = false;

  constructor(public readonly currencyManager: CurrencyManager) {}

  protected readonly formatActivityDuration = formatActivityDuration;
  protected readonly getActivityDestinations = getActivityDestinations;
  protected readonly isActivityPickupIncluded = isActivityPickupIncluded;
}
