<div id="terms">
  <div class="content-wrap">
    <h3>riyado-ADVENTURE.COM TERMS AND CONDITIONS</h3>

    <p>Updated on May 22, 2024</p>

    <h4>1) ABOUT US AND HOW TO CONTACT US</h4>

    <p>
      Riyado-adventure.com is a website operated by Lusora LLC, a limited
      liability company (”we”, “our” or “us”).
    </p>

    <p>To contact us, please email or call our customer service team.</p>

    <h4>2) ABOUT THESE TERMS</h4>

    <p>
      Our Website helps you gather information about adventure travel and check
      the availability of adventure travel packages, tours, and activities
      (“Travel Products”) offered by third-party suppliers (“Suppliers”).
    </p>

    <p>
      When you book a Travel Product, your booking is subject to the Supplier’s
      terms and conditions, forming a separate legal agreement between you and
      the Supplier. It is important to read these terms carefully.
    </p>

    <p>
      Different terms apply to different Travel Products, which will be provided
      during the booking process. You should review them before booking.
    </p>

    <p>
      These terms also set the rules for using our Website, including content
      standards for uploading content, linking to our Website, or interacting
      with it in any way.
    </p>

    <p>
      By using our Website or completing a booking with us, you confirm that you
      accept these terms and the Supplier’s terms and conditions and agree to
      comply with them.
    </p>

    <p>
      We may update these terms from time to time. The date of the latest update
      will be noted at the top. Please check these terms every time you use our
      Website or complete a booking to ensure you understand the terms that
      apply.
    </p>

    <h4>3) RULES WHEN USING OUR WEBSITE</h4>

    <h5>WE MAY MAKE CHANGES TO OUR WEBSITE</h5>

    <p>
      We may update and change our Website to reflect changes in our services,
      users’ needs, and business priorities. We will try to give you reasonable
      notice of any major changes.
    </p>

    <h5>WE MAY SUSPEND OR WITHDRAW OUR WEBSITE</h5>

    <p>Our Website is provided free of charge.</p>

    <p>
      We do not guarantee that our Website, or any content on it, will always be
      available or uninterrupted. We may suspend, withdraw, or restrict the
      availability of all or any part of our Website for business and
      operational reasons. We will try to give you reasonable notice of any
      suspension or withdrawal.
    </p>

    <p>
      The operation of our Website relies on the internet and devices. You agree
      that we will not be liable for any losses due to the Website being
      unavailable because of events beyond our control, such as internet,
      system, or device instability, viruses, or hacker attacks.
    </p>

    <p>
      You are responsible for providing the necessary equipment (including an
      appropriate device and internet connection) and ensuring that all persons
      accessing our Website through your internet connection are aware of these
      terms and comply with them.
    </p>

    <h5>YOUR ACCOUNT DETAILS</h5>

    <p>
      You warrant that all account information you provide is true, accurate,
      current, and complete.
    </p>

    <p>
      If you choose, or are provided with, a user identification code, password,
      or any other piece of information as part of our security procedures, you
      must treat such information as confidential and not disclose it to any
      third party. We will not be liable for any losses resulting from your
      failure to keep this information confidential.
    </p>

    <p>
      We have the right to disable any user identification code or password,
      whether chosen by you or allocated by us, if in our opinion you have
      failed to comply with any of these terms.
    </p>

    <p>
      If you know or suspect that anyone other than you knows your user
      identification code or password, you must promptly notify us. If you have
      saved payment details to your account, you should also contact your
      payment account provider to mitigate any potential losses.
    </p>

    <p>
      You agree to cooperate with any necessary anti-fraud or anti-money
      laundering checks we may need to conduct.
    </p>

    <p>
      You are responsible for maintaining the security of your account
      information and password. If you suspect unauthorized or fraudulent
      activity on your account, contact our Customer Service immediately. We
      assume no responsibility for such situations.
    </p>

    <h5>HOW YOU MAY USE MATERIAL ON OUR WEBSITE</h5>

    <p>
      We are the owner or the licensee of all intellectual property rights on
      our Website. All rights are reserved.
    </p>

    <p>
      You may print off copies and download extracts of any page(s) from our
      Website for your personal use and draw the attention of others to content
      posted on our Website.
    </p>

    <p>
      You must not modify the paper or digital copies of any materials you have
      printed off or downloaded, and you must not use any illustrations,
      photographs, video or audio sequences, or any graphics separately from any
      accompanying text.
    </p>

    <p>
      Our status (and that of any identified contributors) as the authors of
      content on our Website must always be acknowledged.
    </p>

    <p>
      You must not use any part of the content on our Website for commercial
      purposes without obtaining a license from us or our licensors.
    </p>

    <p>
      If you print off, copy, or download any part of our Website in breach of
      these terms, your right to use our Website will cease immediately and you
      must, at our option, return or destroy any copies of the materials you
      have made.
    </p>

    <h5>WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO</h5>

    <p>
      Where our Website contains links to other sites and resources provided by
      third parties, these links are provided for your information only. Such
      links should not be interpreted as approval by us of those linked websites
      or the information you may obtain from them.
    </p>

    <p>We have no control over the contents of those sites or resources.</p>

    <h5>USER-GENERATED CONTENT IS NOT APPROVED BY US</h5>

    <p>
      This Website may include information and materials uploaded by other users
      of the Website, including discussion forums, bulletin boards, and review
      services. This information and these materials have not been verified or
      approved by us. The views expressed by other users on our Website do not
      represent our views or values. We expressly exclude our liability for any
      loss or damage arising from the use of any interactive area by a user in
      contravention of our content standards.
    </p>

    <p>
      If you wish to complain about information and materials uploaded by other
      users, please contact us.
    </p>

    <h5>PROHIBITED USES</h5>

    <p>
      You may only use our Website for lawful purposes. You must not use our
      Website:
    </p>

    <ul>
      <li>
        In any way that breaches any applicable local, national, or
        international law or regulation.
      </li>
      <li>
        In any way that is unlawful, fraudulent, unauthorized, or abusive, or
        has any such purpose or effect.
      </li>
      <li>
        For the purpose of harming or attempting to harm minors in any way.
      </li>
      <li>
        To send, knowingly receive, upload, download, use, or re-use any
        material which does not comply with our content standards.
      </li>
      <li>
        To transmit, or procure the sending of, any unsolicited or unauthorized
        advertising or promotional material or any other form of similar
        solicitation (spam).
      </li>
      <li>
        To knowingly transmit any data, send or upload any material that
        contains viruses, trojan horses, worms, time-bombs, keystroke loggers,
        spyware, adware, or any other harmful programs or similar computer code
        designed to adversely affect the operation of any computer software or
        hardware.
      </li>
    </ul>

    <p>You also agree:</p>

    <ul>
      <li>
        Not to reproduce, duplicate, copy, or re-sell any part of our Website in
        contravention of these terms.
      </li>
      <li>
        Not to access without authority, interfere with, damage, or disrupt any
        part of our Website; any equipment or network on which our Website is
        stored; any software used in the provision of our Website; or any
        equipment, network, or software owned or used by any third party.
      </li>
      <li>
        Not to interfere or attempt to interfere with the normal operation of
        our Website or any activity conducted on our Website.
      </li>
      <li>
        Not to take any action that would result in an unreasonably large data
        load on our Website’s network.
      </li>
    </ul>

    <h5>CONTENT STANDARDS</h5>

    <p>
      These content standards apply to any material you contribute to our
      Website (“Contribution”).
    </p>

    <p>
      The content standards must be complied with in spirit as well as to the
      letter. The standards apply to each part of any Contribution as well as to
      its whole.
    </p>

    <p>
      We will determine, at our discretion, whether a Contribution breaches the
      content standards.
    </p>

    <p>A Contribution must:</p>

    <ul>
      <li>Be relevant to your own adventure travel experiences.</li>
      <li>Be accurate (where it states facts).</li>
      <li>Be genuinely held (where it states opinions).</li>
      <li>
        Comply with the law applicable in any country from which it is posted.
      </li>
    </ul>

    <p>A Contribution must not:</p>

    <ul>
      <li>Be defamatory of any person.</li>
      <li>Be obscene, offensive, hateful, or inflammatory.</li>
      <li>Promote sexually explicit material.</li>
      <li>Promote violence.</li>
      <li>
        Promote discrimination based on race, sex, religion, nationality,
        disability, sexual orientation, or age.
      </li>
      <li>
        Infringe any copyright, database right, trade mark, or any other
        intellectual property right of any other person.
      </li>
      <li>Be likely to deceive any person.</li>
      <li>
        Breach any legal duty owed to a third party, such as a contractual duty
        or a duty of confidence.
      </li>
      <li>Promote any illegal activity.</li>
      <li>Be in contempt of court.</li>
      <li>
        Be threatening, abusive, or invade another’s privacy, or cause
        annoyance, inconvenience, or needless anxiety.
      </li>
      <li>
        Be likely to harass, upset, embarrass, alarm, or annoy any other person.
      </li>
      <li>
        Impersonate any person, or misrepresent your identity or affiliation
        with any person.
      </li>
      <li>
        Give the impression that the Contribution emanates from us, if this is
        not the case.
      </li>
      <li>
        Advocate, promote, incite any party to commit, or assist any unlawful or
        criminal act.
      </li>
      <li>
        Contain any advertising or promote any services or web links to other
        sites.
      </li>
    </ul>

    <p>
      We have no obligation to post your Contributions, and we reserve the right
      in our absolute discretion to determine which Contributions are published
      on our Website.
    </p>

    <h5>RIGHTS YOU ARE GIVING US TO USE MATERIAL YOU UPLOAD</h5>

    <p>
      When you upload or post content to our Website, you grant us a perpetual,
      worldwide, non-exclusive, irrevocable, royalty-free, transferable license
      to use, reproduce, distribute, modify, adapt, publish, translate, prepare
      derivative works of, and display such content with or without attribution
      to you in connection with the services provided by the Website and across
      different media without notice, including the use of such content to
      promote the Website and/or the services. You also grant us the right to
      take legal action against any infringement of your or our rights in the
      content. To the extent possible, you expressly waive any and all “moral
      rights” (including rights of attribution or integrity) that may subsist in
      your content and agree that you have no objection to the publication, use,
      modification, deletion, or exploitation of your content. For the avoidance
      of doubt, we reserve the right to remove any content at our sole
      discretion without notice to you.
    </p>

    <p>
      If you believe that any of the content on our Website breaches your
      intellectual property rights, please contact us.
    </p>

    <h5>WE ARE NOT RESPONSIBLE FOR VIRUSES AND YOU MUST NOT INTRODUCE THEM</h5>

    <p>
      We do not guarantee that our Website will be secure or free from bugs or
      viruses.
    </p>

    <p>
      You are responsible for configuring your information technology, computer
      programs, and platform to access our Website. You should use your own
      virus protection software.
    </p>

    <p>
      You must not misuse our Website by knowingly introducing viruses, trojans,
      worms, logic bombs, or other material that is malicious or technologically
      harmful. You must not attempt to gain unauthorized access to our Website,
      the server on which our Website is stored, or any server, computer, or
      database connected to our Website. You must not attack our Website via a
      denial-of-service attack or a distributed denial-of-service attack.
    </p>

    <h5>RULES ABOUT LINKING TO OUR WEBSITE</h5>

    <p>
      You may link to our home page, provided you do so in a way that is fair
      and legal and does not damage our reputation or take advantage of it.
    </p>

    <p>
      You must not establish a link in such a way as to suggest any form of
      association, approval, or endorsement on our part where none exists.
    </p>

    <p>
      You must not establish a link to our Website in any website that is not
      owned by you.
    </p>

    <p>
      Our Website must not be framed on any other site, nor may you create a
      link to any part of our Website other than the home page.
    </p>

    <p>We reserve the right to withdraw linking permission without notice.</p>

    <h4>4) GENERAL BOOKING TERMS (APPLY TO ALL TRAVEL PRODUCTS)</h4>

    <h5>DETAILS AND PRICE</h5>

    <p>
      You confirm that all details provided by you when making your booking
      (such as name, ID details, contact details, payment details, and dates)
      are accurate and complete. If you notify us of any incorrect details
      provided by you by mistake, we will help you try and change the details on
      the booking; however, we cannot guarantee that the Supplier will permit
      this, particularly where a change could amount to a change in identity.
    </p>

    <p>
      We advertise a large number of Travel Products and try hard to ensure that
      the advertised price is always accurate, but sometimes errors do occur. If
      the error should reasonably have been apparent to you, we reserve the
      right to cancel the booking or (if agreed by you) change the price. If
      this happens, we will contact you.
    </p>

    <h5>BOOKING PROCESS</h5>

    <p>
      When your booking is complete, you will receive a confirmation email from
      us. You agree to check the details in the booking confirmation and to
      contact us immediately if any of the details are incorrect.
    </p>

    <p>
      We will assign a Lusora LLC booking number to your booking. It will help
      us if you can tell us the Lusora LLC booking number whenever you contact
      us about your booking.
    </p>

    <p>When using our booking services, you shall not:</p>

    <ul>
      <li>
        Use an invalid bank card or account, or a bank card or account that you
        are not authorized to use.
      </li>
      <li>
        Impersonate any person, or misrepresent your identity or affiliation
        with any person.
      </li>
      <li>Violate any laws, regulations, or regulatory requirements.</li>
    </ul>

    <p>
      All bookings for Travel Products are subject to availability at the time
      of booking. We try hard to make sure that our Website is kept up to date,
      but we do not guarantee that any of the Travel Products on our Website
      will still be available at the time of booking. We will inform you as soon
      as possible after you place a booking if, for any reason, the Travel
      Product you have sought to book with us is not available.
    </p>

    <p>
      We reserve the right to require additional information or verifications to
      validate or confirm your booking. If you do not provide the requested
      information or if we find the provided information unsatisfactory, your
      booking request will be considered incomplete and subsequently canceled.
    </p>

    <h5>PAYMENT AND CURRENCY</h5>

    <p>
      When you book a Travel Product, the terms of payment will be made clear to
      you during the booking process. Some bookings require you to prepay
      online, some require you to pay at the time of using the Travel Product
      (e.g., when you check-in at a hotel). If any sum is not paid in full in
      accordance with the terms of your booking, we may cancel your booking
      immediately.
    </p>

    <p>
      Exchange rates are calculated in real time. When you prepay online, you
      will pay the exact amount shown on the payment page. However, if you are
      paying in a different currency, the provider of the account you pay with
      may use an alternative currency rate which you are responsible for
      confirming. Your account provider may also charge you a fee for
      international payments.
    </p>

    <p>
      If you encounter a problem when you submit your payment, please do not
      submit the payment again. You can confirm whether your booking was
      successfully made by contacting us.
    </p>

    <p>
      Any refunds will be paid back to the original payment method unless we
      agree otherwise. The date that any refund will be paid into your account
      is subject to the provider of your account.
    </p>

    <p>
      We take the security of your personal and payment information seriously
      and have robust measures in place to ensure that your details are kept
      safe.
    </p>

    <h5>TRAVEL PREPARATIONS</h5>

    <p>
      It is your responsibility to ensure that you have appropriate insurance
      and the required travel documents for your trip. This includes (but is not
      limited to) your passport/national ID card, visas, and medical documents
      (such as proof of vaccinations).
    </p>

    <p>
      By offering Travel Products to or in a particular destination, we are not
      advising or confirming that you can travel to and/or around that
      destination without risk. You are responsible for checking for any travel
      warnings or restrictions in the destination you are traveling to and/or
      around, both before you book and before you travel.
    </p>

    <p>
      It is your responsibility to ensure that you obtain any vaccinations
      required for your trip, and to follow any medical advice you have
      received.
    </p>

    <h5>SERVICE GUARANTEE</h5>

    <p>Please contact us for information on our Service Guarantee.</p>

    <h5>MEMBERSHIP AND REWARDS</h5>

    <p>
      If you register as a member of Lusora LLC, you can access our membership
      tier system and rewards. More information and the terms and conditions
      that apply can be found on our Website.
    </p>

    <h4>5) LIABILITY</h4>

    <p>
      MUCH OF THE INFORMATION ON OUR WEBSITE HAS BEEN PROVIDED BY THE RELEVANT
      SUPPLIERS AND/OR USERS OF OUR WEBSITE WHO HAVE PROVIDED REVIEWS OF THE
      TRAVEL PRODUCTS. WE WILL ENDEAVOR TO ENSURE THAT THE INFORMATION IS
      ACCURATE AND COMPLETE, HOWEVER TO THE EXTENT PERMITTED BY LAW, WE SHALL
      NOT BE LIABLE FOR ANY LOSS OR DAMAGE INCURRED BY YOU AS A RESULT OF THE
      INACCURACY OR INCOMPLETENESS OF THE INFORMATION.
    </p>

    <p>
      NO REPRESENTATIONS, WARRANTIES, OR CONDITIONS ARE GIVEN BY US IN RESPECT
      OF ANY INFORMATION WHICH IS PROVIDED TO YOU ON OUR WEBSITE AND ANY SUCH
      REPRESENTATIONS, WARRANTIES, OR CONDITIONS ARE EXCLUDED, SAVE TO THE
      EXTENT THAT SUCH EXCLUSION IS PROHIBITED BY LAW.
    </p>

    <p>
      TO THE EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR ANY LOSS OR
      DAMAGE INCURRED BY YOU AS A RESULT OF:
    </p>

    <ul>
      <li>
        INCORRECT DETAILS (SUCH AS NAME, ID DETAILS, NATIONALITY, CONTACT
        DETAILS, PAYMENT DETAILS, DATES AND TIMES) SUBMITTED BY YOU WHEN MAKING
        YOUR BOOKING.
      </li>
      <li>
        YOUR FAILURE TO OBTAIN THE CORRECT DOCUMENTATION, SUCH AS A PASSPORT,
        VISA, OR PROOF OF VACCINATIONS, REQUIRED FOR YOUR TRAVEL PRODUCT.
      </li>
      <li>YOUR FAILURE TO OBTAIN APPROPRIATE INSURANCE FOR YOUR TRIP.</li>
      <li>
        YOU BEING UNABLE TO USE A TRAVEL PRODUCT DUE TO YOUR FAILURE TO COMPLY
        WITH THE RELEVANT SUPPLIER’S TERMS AND CONDITIONS.
      </li>
      <li>
        YOU BEING UNABLE TO USE A TRAVEL PRODUCT DUE TO YOUR FAILURE TO ARRIVE
        ON TIME OR ALLOW SUFFICIENT TIME FOR CHECK-IN, BOARDING, OR (WHERE
        RELEVANT) PASSPORT CONTROL AND SECURITY CHECKS.
      </li>
      <li>
        YOUR BEHAVIOR BEING DEEMED AS UNACCEPTABLE BY THE RELEVANT SUPPLIER.
      </li>
      <li>ANY BOOKINGS, INCLUDING DUPLICATE BOOKINGS, MADE BY YOU IN ERROR.</li>
      <li>
        ANY FEES CHARGED BY YOUR ACCOUNT PROVIDER FOR INTERNATIONAL PAYMENTS,
        ALTERNATIVE EXCHANGE RATES USED BY YOUR ACCOUNT PROVIDER WHEN PAYING IN
        A DIFFERENT CURRENCY OR ANY CHANGES IN THE EXCHANGE RATE BETWEEN THE
        TIME OF BOOKING AND THE TIME OF PAYMENT.
      </li>
      <li>ANY DIRECT COMMUNICATIONS BETWEEN YOU AND THE RELEVANT SUPPLIER.</li>
      <li>ANY INACCURATE GUEST OR STAR RATING.</li>
      <li>
        FALSE, INACCURATE, OUTDATED, OR INCOMPLETE PAYMENT INFORMATION PROVIDED
        BY YOU.
      </li>
      <li>
        THE USE OF ANY INTERACTIVE AREA BY A USER OF OUR WEBSITE IN
        CONTRAVENTION OF OUR CONTENT STANDARDS SET OUT IN THESE TERMS.
      </li>
      <li>
        YOU TRAVELING TO AND/OR AROUND AN AREA WITH A TRAVEL WARNING OR TRAVEL
        RESTRICTIONS IN PLACE.
      </li>
      <li>
        EVENTS OUTSIDE OF OUR CONTROL, INCLUDING BUT NOT LIMITED TO:
        <ul>
          <li>
            ACTS OF GOD, FLOOD, DROUGHT, EARTHQUAKE, OR OTHER NATURAL DISASTER;
          </li>
          <li>EPIDEMIC OR PANDEMIC;</li>
          <li>
            TERRORIST ATTACK, WAR, COMMOTION OR RIOTS, THREAT OF OR PREPARATION
            FOR WAR, ARMED CONFLICT, IMPOSITION OF SANCTIONS, EMBARGO, OR
            BREAKING OFF OF DIPLOMATIC RELATIONS;
          </li>
          <li>NUCLEAR, CHEMICAL, OR BIOLOGICAL CONTAMINATION OR SONIC BOOM;</li>
          <li>
            ANY LAW OR ANY ACTION TAKEN BY A GOVERNMENT OR PUBLIC AUTHORITY; AND
          </li>
          <li>
            INTERNET, SYSTEM, OR DEVICE INSTABILITY, COMPUTER VIRUSES, AND
            HACKER ATTACKS.
          </li>
        </ul>
      </li>
    </ul>

    <p>
      PLEASE NOTE THAT WE ONLY PROVIDE OUR WEBSITE FOR DOMESTIC AND PRIVATE USE.
      YOU AGREE NOT TO USE OUR WEBSITE FOR ANY COMMERCIAL OR BUSINESS PURPOSES,
      AND WE HAVE NO LIABILITY TO YOU FOR ANY LOSS OF PROFIT, LOSS OF BUSINESS,
      BUSINESS INTERRUPTION, OR LOSS OF BUSINESS OPPORTUNITY.
    </p>

    <h5>BREACH OF THESE TERMS BY YOU</h5>

    <p>
      Failure to comply with these terms or applicable laws may result in our
      taking any or all of the following actions without prior notice:
    </p>

    <ul>
      <li>
        Reject or cancel any bookings associated with your name, email address,
        or account, including on behalf of the Suppliers;
      </li>
      <li>
        Cancel or revoke any rewards or benefits that you may earn or have
        previously earned;
      </li>
      <li>
        Immediate, temporary, or permanent withdrawal of your right to use our
        Website and/or our booking services;
      </li>
      <li>
        Immediate, temporary, or permanent removal of any Contribution uploaded
        by you to our Website;
      </li>
      <li>Issue a warning to you;</li>
      <li>
        Legal proceedings against you for reimbursement of all costs on an
        indemnity basis (including, but not limited to, reasonable
        administrative and legal costs) resulting from the breach;
      </li>
      <li>Further legal action against you;</li>
      <li>
        Disclosure of such information to law enforcement authorities as we
        reasonably feel is necessary or as required by law; and
      </li>
      <li>
        Any other measures deemed appropriate by us at our sole discretion.
      </li>
    </ul>

    <p>
      You agree that we will not be liable for any loss or damage arising from
      your failure to comply with these terms. You are responsible for any
      losses suffered by us as a result of any use by you of our Website and/or
      booking services that is not in accordance with these terms.
    </p>

    <h5>COMPLAINTS</h5>

    <p>
      If you have any questions or complaints about our Website or booking
      services, please contact us.
    </p>

    <h5>WHICH COUNTRY’S LAWS APPLY TO ANY DISPUTES?</h5>

    <p>
      These terms and any dispute or claim (including non-contractual disputes
      or claims) arising out of them, their subject matter, or formation shall
      be governed by and construed in accordance with the law of Singapore.
    </p>

    <h5>LANGUAGE</h5>

    <p>
      Where the original English version of these terms has been translated into
      other languages, the English version shall prevail in the event of any
      inconsistencies between the versions.
    </p>

    <h5>GENERAL</h5>

    <p>
      These terms are between you and us. No other person shall have any rights
      to enforce any of these terms.
    </p>

    <p>
      If any court or relevant authority finds that part of these terms are
      illegal, the rest will continue in full force and effect.
    </p>

    <p>
      Even if we delay enforcing these terms, we may still take steps against
      you at a later date.
    </p>
  </div>
</div>
