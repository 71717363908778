import { Component } from '@angular/core';
import { ApiHelper } from '../../core/api/api.helper';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-not-found',
  styleUrls: ['./not-found.component.scss'],
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent {
  constructor(
    public readonly apiHelper: ApiHelper,
    private titleService: Title,
  ) {
    titleService.setTitle('Riyado - Not Found');
  }
}
