/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getPlaceCollectionByIndex } from '../fn/place-collections/get-place-collection-by-index';
import { GetPlaceCollectionByIndex$Params } from '../fn/place-collections/get-place-collection-by-index';
import { getPlaceCollections } from '../fn/place-collections/get-place-collections';
import { GetPlaceCollections$Params } from '../fn/place-collections/get-place-collections';
import { PlaceCollectionDto } from '../models/place-collection-dto';

@Injectable({ providedIn: 'root' })
export class PlaceCollectionsApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPlaceCollections()` */
  static readonly GetPlaceCollectionsPath = '/api/v1/places/collections';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlaceCollections()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceCollections$Response(params?: GetPlaceCollections$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PlaceCollectionDto>>> {
    return getPlaceCollections(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlaceCollections$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceCollections(params?: GetPlaceCollections$Params, context?: HttpContext): Observable<Array<PlaceCollectionDto>> {
    return this.getPlaceCollections$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlaceCollectionDto>>): Array<PlaceCollectionDto> => r.body)
    );
  }

  /** Path part for operation `getPlaceCollectionByIndex()` */
  static readonly GetPlaceCollectionByIndexPath = '/api/v1/places/collections/{index}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlaceCollectionByIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceCollectionByIndex$Response(params: GetPlaceCollectionByIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<PlaceCollectionDto>> {
    return getPlaceCollectionByIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlaceCollectionByIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceCollectionByIndex(params: GetPlaceCollectionByIndex$Params, context?: HttpContext): Observable<PlaceCollectionDto> {
    return this.getPlaceCollectionByIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlaceCollectionDto>): PlaceCollectionDto => r.body)
    );
  }

}
