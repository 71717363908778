import { Component, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  RegionDto,
  RegionsApiService,
} from '../../core/api/generated/riyado-api';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';
import { getMainPictureUrl } from '../../places/places.utils';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

dayjs.extend(utc);

@Component({
  selector: 'app-regions',
  styleUrls: ['regions.component.scss'],
  templateUrl: 'regions.component.html',
})
export class RegionsListComponent implements OnInit {
  public regions: RegionDto[];

  constructor(
    private readonly regionsApiService: RegionsApiService,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.generatePageMeta();
  }

  private generatePageMeta() {
    const title = 'Riyado - UAE Regions';
    const description =
      'Explore the diverse regions of the UAE with Riyado. From the bustling cities of Dubai and Abu Dhabi to the serene deserts and coastal areas, discover what each region has to offer. Let Riyado be your guide to the unique landscapes and attractions across the emirates.';
    const keywords =
      'Riyado UAE regions, explore UAE, Dubai region, Abu Dhabi region, UAE landscapes, coastal areas UAE, desert regions UAE, Riyado travel guide, popular emirates, UAE attractions, explore regions UAE, Riyado service';

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaService.updateTag({
      name: 'og:description',
      content: description,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: `${environment.dashboardUrl}/assets/logo.png`,
    });

    this.metaService.updateTag({
      name: 'keywords',
      content: keywords,
    });
  }

  ngOnInit(): void {
    this.updateData();
  }

  getBreadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: 'Regions',
        url: `/regions`,
      },
    ];
  }

  public updateData() {
    this.regionsApiService.getRegions().subscribe((data) => {
      this.regions = data;
    });
  }

  protected readonly getMainPictureUrl = getMainPictureUrl;
}
