import { Component, OnInit, ViewChild } from '@angular/core';
import {
  PlacePreviewDto,
  PlacesApiService,
  RegionDto,
  RegionsApiService,
} from '../../core/api/generated/riyado-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { OpeningHoursType, placeActivities } from '../places.types';
import { ActivatedRoute } from '@angular/router';
import { PlacesMapComponent } from '../../core/places-map/places-map.component';
import { FilterCheckboxOption } from '../../core/filter-checkbox/filter-checkbox.component';
import { orderBy } from 'lodash';
import { SearchPlaces$Params } from '../../core/api/generated/riyado-api/fn/places/search-places';
import { CurrencyManager } from '../../core/currency-manager';
import { FilterSliderComponent } from '../../core/filter-slider/filter-slider.component';
import { formatMinutes } from '../places.utils';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject, takeUntil } from 'rxjs';
import { SearchPlacesPreview$Params } from '../../core/api/generated/riyado-api/fn/places/search-places-preview';
import { ListPopoverItem } from '../../core/custom-select/custom-select.component';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

dayjs.extend(utc);

@Component({
  selector: 'app-places-list-page',
  styleUrls: ['places-list-page.component.scss'],
  templateUrl: 'places-list-page.component.html',
})
export class PlacesListPageComponent implements OnInit {
  public places?: PlacePreviewDto[];
  public regions: RegionDto[] = [];
  public totalItems = 0;
  public totalFilteredItems = 0;

  public page = 1;
  public limit = 25;
  public isLoading = false;
  public isMapLoading = false;
  public isMapView = false;
  public isFiltersOpen = false;
  public isHorizontal = true;

  public sortBy: SearchPlacesPreview$Params['sortBy'] = 'Rating';

  private destroy$ = new Subject<void>();

  @ViewChild(PlacesMapComponent)
  public map: PlacesMapComponent;

  @ViewChild('costsFilter', { static: true })
  public costsFilterComponent: FilterSliderComponent;

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly regionsApiService: RegionsApiService,
    private route: ActivatedRoute,
    public readonly currencyManager: CurrencyManager,
    private readonly breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.generatePageMeta;
  }

  public generatePageMeta() {
    const title = 'Riyado - Explore Places';
    const description =
      'Explore the best places to visit in the UAE with Riyado. Discover iconic landmarks, hidden gems, and must-see attractions across popular emirates like Dubai and Abu Dhabi. Let Riyado guide you to the top destinations in the UAE.';
    const keywords =
      'Riyado explore places UAE, top destinations UAE, Dubai landmarks, Abu Dhabi attractions, must-see places UAE, Riyado travel guide, explore UAE, popular emirates, UAE tourism, hidden gems UAE, Riyado service';

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaService.updateTag({
      name: 'og:description',
      content: description,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: `${environment.dashboardUrl}/assets/logo.png`,
    });

    this.metaService.updateTag({
      name: 'keywords',
      content: keywords,
    });
  }

  public closeFilters(e: MouseEvent) {
    const barBody = document.querySelector('#filters .filters-body');
    const clickedInside = barBody?.contains(e.target as Node);

    if (!clickedInside) {
      this.isFiltersOpen = false;
    }
  }

  public toggleMapView(isMapView: boolean) {
    if (isMapView === this.isMapView) {
      return;
    }

    this.isMapView = isMapView;

    setTimeout(() => {
      if (this.isMapView) {
        this.updateMapPlaces();
      } else {
        this.map?.cleanMap();
        this.updatePlaces();
      }
    }, 0);
  }

  public activitiesFilterOptions: FilterCheckboxOption[] = orderBy(
    Object.entries(placeActivities).map(([i, val]) => ({
      label: val.name,
      value: i,
    })),
    'label',
    'asc',
  );
  public activitiesFilter: PlacePreviewDto['activities'] = [];

  public regionsFilterOptions: FilterCheckboxOption[] = [];
  public regionsFilter: number[] = [];

  public hoursFilterOptions: FilterCheckboxOption[] = [
    {
      label: 'Open now',
      value: OpeningHoursType.OpenNow,
    },
    {
      label: 'Morning (7AM-12PM)',
      value: OpeningHoursType.Morning,
    },
    {
      label: 'Afternoon (12PM-5PM)',
      value: OpeningHoursType.Afternoon,
    },
    {
      label: 'Evening (5PM-12AM)',
      value: OpeningHoursType.Evening,
    },
    {
      label: 'Night (12AM-7AM)',
      value: OpeningHoursType.Night,
    },
  ];
  public hoursFilter: string[] = [];

  public additionalFilterOptions: FilterCheckboxOption[] = [
    {
      label: 'Can visit for free',
      value: 'free',
    },
    {
      label: 'Skip closed places',
      value: 'skip-closed',
    },
  ];
  public additionalFilter: string[] = [];

  public durationFilter: number[] = [];

  public costsFilterAed: number[] = [];
  public maxCostsAed: number = 0;
  public maxCosts: number;

  public ratingFilterOptions: FilterCheckboxOption[] = [
    {
      label: '★ ★ ★ ★ ★',
      value: 5,
    },
    {
      label: '★ ★ ★ ★',
      value: 4,
    },
    {
      label: '★ ★ ★',
      value: 3,
    },
    {
      label: '★ ★',
      value: 2,
    },
    {
      label: '★',
      value: 1,
    },
  ];
  public ratingFilter: number[] = [];

  ngOnInit() {
    const initialValue =
      this.breakpointObserver.isMatched('(max-width: 820px)');
    this.isHorizontal = !initialValue;

    this.updateFiltersData();
    this.parseQueryParams();
    this.updatePlaces();

    this.currencyManager.currentCurrency$.subscribe(() => {
      const currency = this.currencyManager.getCurrency();

      this.maxCosts = this.currencyManager.convertCurrency(this.maxCostsAed);

      this.costsFilterComponent.valueMin = this.currencyManager.convertCurrency(
        this.costsFilterComponent.valueMin,
        currency === 'AED' ? 'USD' : 'AED',
      );
      this.costsFilterComponent.valueMax = this.currencyManager.convertCurrency(
        this.costsFilterComponent.valueMax,
        currency === 'AED' ? 'USD' : 'AED',
      );
    });

    this.breakpointObserver
      .observe('(max-width: 820px)')
      .subscribe((result) => {
        this.isHorizontal = !result.matches;
      });
  }

  public initCostsFilter() {
    this.maxCosts = this.currencyManager.convertCurrency(this.maxCostsAed);
    this.costsFilterComponent.valueMin = 0;
    this.costsFilterComponent.valueMax = this.maxCosts;
  }

  public updateDurationFilter(value: number[]) {
    this.durationFilter = value;
    this.onFiltersChange();
  }

  public updateCostsFilter(value: number[]) {
    this.costsFilterAed = [
      this.currencyManager.convertCurrency(
        value[0],
        this.currencyManager.getCurrency(),
        'AED',
      ),
      this.currencyManager.convertCurrency(
        value[1],
        this.currencyManager.getCurrency(),
        'AED',
      ),
    ];
    this.onFiltersChange();
  }

  public parseQueryParams() {
    const params = this.route.snapshot.queryParamMap;
    const activities = params.get('activities')?.split(',') || [];

    if (activities.length > 0) {
      this.activitiesFilter = <any>activities;
    }

    const regions = params.get('regions')?.split(',') || [];

    if (regions.length > 0) {
      this.regionsFilter = regions.map(Number);
    }

    const page = parseInt(String(params.get('page')));
    if (page) {
      this.page = page;
    }
  }

  public placeTrackBy(index: number, place: PlacePreviewDto) {
    return place.id;
  }

  public formatCosts(val: number) {
    const currency = this.currencyManager.getCurrency();

    return this.currencyManager.format(val, currency, currency);
  }

  public formatDuration(val: number) {
    return formatMinutes(val);
  }

  public onPageChanged(page: number) {
    this.page = page;

    this.updatePlaces();
  }

  public onFiltersChange() {
    this.page = 1;
    //this.places = undefined;

    setTimeout(() => {
      if (this.isMapView) {
        this.updateMapPlaces();
      } else {
        this.updatePlaces();
      }
    }, 0);
  }

  public updateFiltersData() {
    this.regionsApiService.getRegions().subscribe((regions) => {
      this.regions = regions;

      this.regionsFilterOptions = this.regions.map((r) => ({
        label: r.name,
        value: r.id,
      }));
    });

    this.placesApiService.getMaxPlacesCost().subscribe((data) => {
      this.maxCostsAed = data.valueAed;
      this.initCostsFilter();
    });
  }

  public get totalPages() {
    return Math.ceil(this.totalFilteredItems / this.limit) || 1;
  }

  private getFilters(): SearchPlaces$Params {
    const isFreeOption = !!this.additionalFilter.find((f) => f === 'free');
    const skipClosed = !!this.additionalFilter.find((f) => f === 'skip-closed');

    return {
      activities: this.activitiesFilter,
      workingTimes: <any>this.hoursFilter,
      regionIds: this.regionsFilter,
      minMaxDuration: this.durationFilter,
      minMaxPrice: this.costsFilterAed,
      ratings: this.ratingFilter,
      isFreeOption: isFreeOption || undefined,
      skipClosed: skipClosed || undefined,
    };
  }

  public updateSortBy(val: ListPopoverItem) {
    this.sortBy = <SearchPlacesPreview$Params['sortBy']>val.value;
    this.onFiltersChange();
  }

  public updatePlaces() {
    this.isLoading = true;

    this.destroy$.next();

    this.placesApiService
      .searchPlacesPreview({
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        sortBy: this.sortBy,
        ...this.getFilters(),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.places = data.items;
          this.totalItems = data.totalItems;
          this.totalFilteredItems = data.totalFilteredItems;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  public updateMapPlaces() {
    this.isMapLoading = true;

    this.placesApiService
      .searchPlacesPreview({
        ...this.getFilters(),
      })
      .subscribe({
        next: (data) => {
          this.totalItems = data.totalItems;
          this.totalFilteredItems = data.totalFilteredItems;

          this.map?.renderPlaces(data.items.map((p) => ({ data: p })) || []);
          this.isMapLoading = false;
        },
        error: () => {
          this.isMapLoading = false;
        },
      });
  }
}
