export const routeTypes = {
  RoadTrip: {
    name: 'Road trip',
    icon: 'matDirectionsCarOutline',
  },
  Hiking: {
    name: 'Hiking',
    icon: 'matNordicWalkingOutline',
  },
  CityTour: {
    name: 'City tour',
    icon: 'matLocationCityOutline',
  },
};
