import { Component, Input } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { PlacePreviewDto } from '../../core/api/generated/riyado-api';
import {
  formatDistance,
  formatMinutes,
  getActivityName,
  getMainPictureUrl,
} from '../places.utils';

dayjs.extend(utc);

@Component({
  selector: 'app-place-near-by',
  styleUrls: ['place-near-by.component.scss'],
  templateUrl: 'place-near-by.component.html',
})
export class PlaceNearByComponent {
  @Input()
  public place: PlacePreviewDto;

  @Input()
  public distance?: number;

  protected readonly getActivityName = getActivityName;
  protected readonly formatMinutes = formatMinutes;
  protected readonly formatDistance = formatDistance;
  protected readonly getMainPictureUrl = getMainPictureUrl;

  getPlaceActivities() {
    return this.place.activities.slice(0, 3);
  }
}
