<div
  class="entities-preview-list"
  [class.nowrap]="nowrap"
  *ngIf="isLoad() || !isEmpty()">
  <h3 class="block-title">{{ title }}</h3>

  <div class="block-subtitle" *ngIf="subtitle">
    <h4 class="subtitle">{{ subtitle }}</h4>

    <div class="swipe-block" [class.scrolled]="isScrolled">
      <!--      <div class="swipe" *ngIf="nowrap && !isLoad()">-->
      <!--        <ng-lottie-->
      <!--          *ngIf="apiHelper.isBrowser"-->
      <!--          width="60px"-->
      <!--          containerClass="loading-box"-->
      <!--          [options]="{-->
      <!--            path: 'assets/swipe_right.json'-->
      <!--          }" />-->
      <!--      </div>-->

      <a
        [routerLink]="routerLinkValue"
        [queryParams]="queryParams"
        *ngIf="routerLinkValue"
        class="see-all"
        >Show all</a
      >
    </div>
  </div>

  <div class="entities-body" #entitiesBody>
    <div class="entities-list places-entities-list" *ngIf="places">
      <app-place-preview
        *ngFor="let place of places"
        [newTab]="newTab"
        [place]="place"></app-place-preview>
    </div>

    <div class="entities-list routes-entities-list" *ngIf="routes">
      <app-route-preview
        *ngFor="let route of routes"
        [newTab]="newTab"
        [route]="route"></app-route-preview>
    </div>

    <div class="entities-list activity-entities-list" *ngIf="activities">
      <app-activity-preview
        *ngFor="let activity of activities"
        [newTab]="newTab"
        [activity]="activity"></app-activity-preview>
    </div>

    <div class="entities-list collections-entities-list" *ngIf="collections">
      <app-place-collection-preview
        *ngFor="let collection of collections"
        [newTab]="newTab"
        [collection]="collection"></app-place-collection-preview>
    </div>

    <div class="regions-list" *ngIf="regions">
      <ng-template #regionTemplate let-region>
        <a
          class="region"
          routerLink="/regions/{{ region.index }}"
          [target]="newTab ? '_blank' : '_self'">
          <div class="img">
            <img
              [ngSrc]="getMainPictureUrl([region.file], 'medium')"
              fill
              [alt]="region.name" />
          </div>
          <h3 class="title">{{ region.name }}</h3>
        </a>
      </ng-template>

      <div class="first-row">
        <ng-container *ngFor="let region of regions.slice(0, 2)">
          <ng-container
            *ngTemplateOutlet="
              regionTemplate;
              context: { $implicit: region }
            "></ng-container>
        </ng-container>
      </div>

      <div class="second-row">
        <ng-container *ngFor="let region of regions.slice(2, 4)">
          <ng-container
            *ngTemplateOutlet="
              regionTemplate;
              context: { $implicit: region }
            "></ng-container>
        </ng-container>
      </div>
    </div>

    <div class="loader-content" *ngIf="isLoad()">
      <app-loader [animation]="false" [count]="4"></app-loader>
    </div>
  </div>
</div>
