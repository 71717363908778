<div class="place-preview" [class.horizontal]="horizontal">
  <a
    class="photo"
    routerLink="/places/{{ place.index }}"
    [target]="newTab ? '_blank' : '_self'">
    <img
      [ngSrc]="getMainPictureUrl([place.preview], 'small')"
      fill
      alt="{{ place.name }}" />

    <div class="closed" *ngIf="place.closed">
      <ng-icon name="bootstrapDashCircleFill"></ng-icon>
      {{ formatClosedType(place) }}
    </div>

    <div class="favourite" *ngIf="place.favourite">
      Top Picks
      <ng-icon name="matStarOutline"></ng-icon>
    </div>
  </a>

  <div class="info">
    <a
      class="title"
      routerLink="/places/{{ place.index }}"
      [target]="newTab ? '_blank' : '_self'">
      <h3>
        {{ place.name }}
      </h3>
    </a>

    <!--      <div class="rating-number" *ngIf="place.rating">-->
    <!--        <ng-icon name="matStarOutline"></ng-icon>-->
    <!--        <div class="value">{{ place.rating! }}</div>-->
    <!--      </div>-->

    <div class="activities" *ngIf="place.activities">
      <div
        class="activity"
        *ngFor="let a of getPlaceActivities()"
        [title]="getActivityName(a)">
        <ng-icon [name]="getActivityIcon(a)"></ng-icon>
        <span>{{ getActivityName(a) }}</span>
      </div>
    </div>

    <div class="short-info">
      <div class="closed" *ngIf="place.closed">
        <ng-icon name="bootstrapDashCircleFill"></ng-icon>
        <div>{{ formatClosedType(place) }}</div>
      </div>

      <div class="duration" *ngIf="place.suggestedVisitDuration">
        <ng-icon name="matHourglassTopOutline"></ng-icon>
        <div>{{ formatMinutes(place.suggestedVisitDuration) }}</div>
      </div>

      <div
        class="costs"
        *ngIf="
          (place.viatorFromPriceAed || place.minPrice) && !place.isFreeOption
        ">
        <ng-icon name="matPaidOutline"></ng-icon>
        <div>
          from
          {{
            currencyManager.format(place.viatorFromPriceAed || place.minPrice)
          }}
        </div>
      </div>

      <div class="costs" *ngIf="place.isFreeOption">
        <ng-icon name="matMoneyOff"></ng-icon>
        <div>Can visit for free</div>
      </div>
    </div>

    <div
      class="description"
      [innerHTML]="removeHtmlTags(place.description)"></div>

    <div class="rating">
      <app-google-rating
        *ngIf="
          (!place.tripadvisorData || !place.tripadvisorData.rating) &&
          place.googleData &&
          place.googleData.rating
        "
        [small]="true"
        [data]="place.googleData"></app-google-rating>

      <app-tripadvisor-rating
        *ngIf="place.tripadvisorData && place.tripadvisorData.rating"
        [data]="place.tripadvisorData"
        [textOnly]="!horizontal"></app-tripadvisor-rating>

      <div
        class="facts"
        [title]="place.facts[0]"
        *ngIf="
          !place.tripadvisorData && !horizontal && place.facts && place.facts[0]
        ">
        {{ place.facts[0] }}
      </div>

      <div class="facts" *ngIf="horizontal && place.facts && place.facts[0]">
        {{ place.facts.slice(0, 3).join(', ') }}
      </div>
    </div>
  </div>
</div>
