import { Component, ViewChild } from '@angular/core';
import { compact, uniq } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import {
  ActivitiesApiService,
  ActivityPreviewDto,
  PlacePreviewDto,
  PlacesApiService,
  RoutePreviewDto,
  RoutesApiService,
  SearchApiService,
} from '../core/api/generated/riyado-api';
import { ApiHelper } from '../core/api/api.helper';
import { AutocompleteInputComponent } from '../core/autocomplete-input/autocomplete-input.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-search-page',
  styleUrls: ['search-page.component.scss'],
  templateUrl: 'search-page.component.html',
})
export class SearchPageComponent {
  public places: PlacePreviewDto[] = [];
  public routes: RoutePreviewDto[] = [];
  public activities: ActivityPreviewDto[] = [];

  public input: string;
  public limit = 30;

  @ViewChild(AutocompleteInputComponent)
  private autocompleteComponent: AutocompleteInputComponent;

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly routesApiService: RoutesApiService,
    private readonly activitiesApiService: ActivitiesApiService,
    private readonly searchApiService: SearchApiService,
    private route: ActivatedRoute,
    private apiHelper: ApiHelper,
    private titleService: Title,
  ) {
    titleService.setTitle('Riyado - Search');
  }

  ngOnInit() {
    if (this.apiHelper.isBrowser) {
      this.route.queryParamMap.subscribe((params) => {
        this.input = params.get('input') || '';

        if (this.input.length >= 1) {
          this.updateData();
        }
      });
    }
  }

  ngAfterViewInit() {
    if (this.input) {
      this.autocompleteComponent.setInitialValue(this.input);
    }
  }

  public updateData() {
    this.searchApiService
      .search({
        input: this.input,
        limit: this.limit,
      })
      .subscribe((data) => {
        const places = uniq(compact(data.places.map((i) => i.index)));

        if (places.length > 0) {
          this.placesApiService
            .getPlacesPreview({
              indexes: places,
              limit: this.limit,
            })
            .subscribe((data) => {
              this.places = data;
            });
        } else {
          this.places = [];
        }

        const routes = uniq(compact(data.routes.map((i) => i.index)));

        if (routes.length > 0) {
          this.routesApiService
            .getRoutesPreview({
              indexes: routes,
              limit: this.limit,
            })
            .subscribe((data) => {
              this.routes = data;
            });
        } else {
          this.routes = [];
        }

        const activities = uniq(compact(data.activities.map((i) => i.index)));

        if (activities.length > 0) {
          this.activitiesApiService
            .searchActivities({
              productCodes: activities,
              limit: this.limit,
            })
            .subscribe((data) => {
              this.activities = data.items;
            });
        } else {
          this.activities = [];
        }
      });
  }
}
