<div id="search_page" class="content-wrap">
  <div class="search-block">
    <div class="input-block">
      <div class="icon-block">
        <ng-icon name="matSearchOutline"></ng-icon>
      </div>

      <app-autocomplete-input
        id="search_page_input"
        placeholder="What are you looking for?"
        [disableEntityRedirect]="true"
        #autocompleteInput></app-autocomplete-input>

      <a
        class="button"
        routerLink="/search"
        [queryParams]="{ input: autocompleteInput.value }">
        <div class="button-label">Search</div>
      </a>
    </div>
  </div>

  <div class="search-input" *ngIf="input">
    Search results for <span>{{ input }}</span>
  </div>

  <div class="places-search-block">
    <app-entities-preview-list
      title="Found places"
      subtitle="First {{ limit }} results"
      [places]="places"></app-entities-preview-list>
  </div>

  <div class="routes-search-block">
    <app-entities-preview-list
      title="Found routes"
      subtitle="First {{ limit }} results"
      [routes]="routes"></app-entities-preview-list>
  </div>

  <div class="activities-search-block">
    <app-entities-preview-list
      title="Found activities"
      subtitle="First {{ limit }} results"
      [activities]="activities"></app-entities-preview-list>
  </div>

  <div
    class="empty"
    *ngIf="!input || (places.length === 0 && routes.length === 0)">
    No results
  </div>
</div>
