import { Component, Input } from '@angular/core';
import {
  PlaceFullWeatherDto,
  PlaceWeatherDto,
} from '../api/generated/riyado-api';
import dayjs from 'dayjs';
import { compact } from 'lodash';

@Component({
  selector: 'app-weather',
  templateUrl: 'weather.component.html',
  styleUrl: 'weather.component.scss',
})
export class WeatherComponent {
  @Input()
  public weather: PlaceFullWeatherDto;

  public get hourlyWeather(): PlaceWeatherDto[] {
    const hourlyWeatherMap = new Map(
      this.weather.hourly.map((w) => [dayjs(w.time).hour(), w]),
    );

    return compact([
      hourlyWeatherMap.get(8),
      hourlyWeatherMap.get(12),
      hourlyWeatherMap.get(16),
      hourlyWeatherMap.get(20),
    ]);
  }
}
