/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PlacesPreviewPaginatedResponseDto } from '../../models/places-preview-paginated-response-dto';

export interface SearchPlacesPreview$Params {
  offset?: number;
  limit?: number;

/**
 * Cultural = 1; Shopping = 2; Entertainment = 3; Natural = 4; Beaches = 5; Family = 6; Trekking = 7; Cycling = 8; Camping = 9; Extrime = 10; Photo = 11; IconicBuilding = 12; ViewPoint = 13; Walking = 14; WaterActivity = 15
 */
  activities?: Array<'Cultural' | 'Shopping' | 'Entertainment' | 'Natural' | 'Beaches' | 'Family' | 'Trekking' | 'Cycling' | 'Camping' | 'Extrime' | 'Photo' | 'IconicBuilding' | 'ViewPoint' | 'Walking' | 'WaterActivity'>;
  minMaxDuration?: Array<number>;
  minMaxPrice?: Array<number>;
  ids?: Array<number>;
  indexes?: Array<string>;
  favourite?: boolean;
  regionIds?: Array<number>;
  workingTimes?: Array<'OpenNow' | 'Morning' | 'Afternoon' | 'Evening' | 'Night'>;
  isFreeOption?: boolean;
  skipClosed?: boolean;
  ratings?: Array<number>;
  sortBy?: 'Rating' | 'Favourite' | 'Price' | 'Random';
}

export function searchPlacesPreview(http: HttpClient, rootUrl: string, params?: SearchPlacesPreview$Params, context?: HttpContext): Observable<StrictHttpResponse<PlacesPreviewPaginatedResponseDto>> {
  const rb = new RequestBuilder(rootUrl, searchPlacesPreview.PATH, 'get');
  if (params) {
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
    rb.query('activities', params.activities, {});
    rb.query('minMaxDuration', params.minMaxDuration, {});
    rb.query('minMaxPrice', params.minMaxPrice, {});
    rb.query('ids', params.ids, {});
    rb.query('indexes', params.indexes, {});
    rb.query('favourite', params.favourite, {});
    rb.query('regionIds', params.regionIds, {});
    rb.query('workingTimes', params.workingTimes, {});
    rb.query('isFreeOption', params.isFreeOption, {});
    rb.query('skipClosed', params.skipClosed, {});
    rb.query('ratings', params.ratings, {});
    rb.query('sortBy', params.sortBy, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PlacesPreviewPaginatedResponseDto>;
    })
  );
}

searchPlacesPreview.PATH = '/api/v1/places/search/preview';
