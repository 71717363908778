<div class="availability-form">
  <div class="date">
    <div class="input-block">
      <ng-icon name="bootstrapCalendar"></ng-icon>
      <input
        [matDatepicker]="picker"
        [formControl]="datePicker"
        (click)="picker.open()"
        [min]="minDate"
        placeholder="Select a date" />
    </div>

    <mat-datepicker #picker></mat-datepicker>
  </div>

  <div class="pax-mix">
    <div
      class="activity-pax-mix"
      appOutsideClick
      (outsideClick)="isVisible = false">
      <div class="pax-mix-button" (click)="isVisible = !isVisible">
        <ng-icon name="bootstrapPerson"></ng-icon>
        <div>{{ getLabel() }}</div>
      </div>

      <div class="pax-mix-popup" *ngIf="isVisible">
        <div class="minmax">
          You can select up to {{ totalMaxValue }} travelers in total
        </div>

        <div class="items">
          <div class="item" *ngFor="let band of activity.pricing.ageBands">
            <div class="name-block">
              <div class="name">
                {{ formatAgeBand(band.ageBand) }}
                <span class="age" *ngIf="band.startAge && band.endAge"
                  >({{ band.startAge }} - {{ band.endAge }} years old)</span
                >
              </div>
              <div
                class="description"
                *ngIf="
                  band.minTravelersPerBooking || band.maxTravelersPerBooking
                ">
                <div *ngIf="band.minTravelersPerBooking">
                  Minimum: {{ band.minTravelersPerBooking }}
                </div>
                <div *ngIf="band.maxTravelersPerBooking">
                  Maximum: {{ band.maxTravelersPerBooking }}
                </div>
              </div>
            </div>

            <div class="value-block">
              <div
                class="less"
                [class.blocked]="isBlocked('min')"
                (click)="less(band.ageBand)">
                <ng-icon name="bootstrapDashCircle"></ng-icon>
              </div>
              <div class="value">{{ getBandValue(band.ageBand) }}</div>
              <div
                class="more"
                [class.blocked]="isBlocked('max')"
                (click)="more(band.ageBand)">
                <ng-icon name="bootstrapPlusCircle"></ng-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="pax-button"
    [class.disabled]="inProgress"
    (click)="checkAvailability()">
    Check availability
  </div>
</div>

<div class="availability-results" [class.open]="showMore" *ngIf="availability">
  <div
    class="option"
    [class.unavailable]="option.availability.length === 0"
    *ngFor="let option of sortedOptions; trackBy: trackBy">
    <div class="left">
      <div class="title">{{ option.title }}</div>
      <div class="description" [innerHTML]="option.description"></div>

      <div class="unavailable-option">There is no available slots</div>

      <div class="times">
        <div
          class="time"
          [class.unavailable]="!a.available"
          *ngFor="let a of option.availability">
          {{ a.startTime }}
        </div>
      </div>
    </div>

    <div class="price">
      <div class="breakdown" *ngIf="getOptionWithMinPrice(option.availability)">
        <div class="from">
          from
          {{
            currencyManager.format(
              getOptionWithMinPrice(option.availability).priceUsd || 0,
              'USD'
            )
          }}
        </div>

        <div class="travelers">
          <div
            class="item"
            *ngFor="
              let t of getOptionWithMinPrice(option.availability).priceBreakdown
            ">
            {{ formatAgeBand(t.ageBand, t.numberOfTravelers) }} x
            {{
              currencyManager.format(t.priceUsd / t.numberOfTravelers, 'USD')
            }}
          </div>
        </div>
      </div>

      <a
        [href]="activity.productUrl"
        [class.disabled]="!option.isAnyAvailableOptions"
        target="_blank"
        class="book-button"
        >Purchase tickets</a
      >
    </div>
  </div>

  <div
    class="show-all-button"
    *ngIf="unavailableAmount > 0"
    (click)="showMore = true">
    Show more <ng-icon name="matKeyboardArrowDownOutline"></ng-icon>
  </div>
</div>
