import { Component, OnInit } from '@angular/core';
import {
  PlaceCollectionDto,
  PlaceCollectionsApiService,
  PlaceDto,
  PlacesApiService,
} from '../../core/api/generated/riyado-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { flatten, uniq } from 'lodash';
import { getMainPictureUrl } from '../places.utils';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

dayjs.extend(utc);

@Component({
  selector: 'app-place-collections-page',
  styleUrls: ['place-collections-page.component.scss'],
  templateUrl: 'place-collections-page.component.html',
})
export class PlaceCollectionsPageComponent implements OnInit {
  public places: PlaceDto[] = [];
  public collections: PlaceCollectionDto[];
  public isHorizontal = true;

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly placeCollectionsApiService: PlaceCollectionsApiService,
    private readonly breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.generatePageMeta();
  }

  public generatePageMeta() {
    const title = 'Riyado - Collections';
    const description =
      'Discover Riyado’s exclusive collections of top places and experiences in the UAE. Explore curated lists featuring the best beaches, tallest buildings, cultural sites, and more across popular emirates like Dubai and Abu Dhabi.';
    const keywords =
      'Riyado collections UAE, best beaches UAE, tallest buildings UAE, Dubai collections, Abu Dhabi collections, top places UAE, cultural sites UAE, Riyado service, explore UAE, popular emirates, UAE attractions';

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaService.updateTag({
      name: 'og:description',
      content: description,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: `${environment.dashboardUrl}/assets/logo.png`,
    });

    this.metaService.updateTag({
      name: 'keywords',
      content: keywords,
    });
  }

  ngOnInit() {
    this.updateData();

    this.breakpointObserver
      .observe('(max-width: 1000px)')
      .subscribe((result) => {
        this.isHorizontal = !result.matches;
      });
  }

  private updateData() {
    this.placeCollectionsApiService.getPlaceCollections().subscribe((data) => {
      this.collections = data;

      const placeIds = uniq(flatten(data.map((i) => i.placeIds)));

      this.placesApiService
        .getPlaces({
          ids: placeIds,
        })
        .subscribe((data) => {
          this.places = data;
        });
    });
  }

  getPlacesByCollectionId(collectionId: number): PlaceDto[] {
    const collection = this.collections.filter((c) => c.id === collectionId)[0];

    if (!collection) {
      return [];
    }

    return this.places.filter((p) => collection.placeIds.includes(p.id));
  }

  protected readonly getMainPictureUrl = getMainPictureUrl;
}
