import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ActivitiesApiService,
  ActivityPreviewDto,
  NearestPlaceDto,
  PlaceDailyOpenHoursDto,
  PlaceDto,
  PlaceFullWeatherDto,
  PlacePreviewDto,
  PlacesApiService,
  RegionDto,
  RegionsApiService,
  ReviewsApiService,
  RoutePreviewDto,
  RoutesApiService,
  TripAdvisorReviewsListDto,
} from '../../core/api/generated/riyado-api';
import { ActivatedRoute } from '@angular/router';
import { IGalleryFile } from '../../core/gallery/gallery.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { compact, sortBy, upperFirst } from 'lodash';
import {
  formatClosedType,
  formatDistance,
  formatMinutes,
  formatWorkingTime,
  getActivityIcon,
  getActivityName,
  getCurrentWorkingTime,
  getMainPictureUrl,
  isOpenNow,
} from '../places.utils';
import { AiChatComponent } from '../../core/ai-chat/ai-chat.component';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';
import { PlacesMapComponent } from '../../core/places-map/places-map.component';
import { ApiHelper } from '../../core/api/api.helper';
import { CurrencyManager } from '../../core/currency-manager';
import { Meta, Title } from '@angular/platform-browser';

dayjs.extend(utc);

@Component({
  selector: 'app-place-full-page',
  styleUrls: ['place-full-page.component.scss'],
  templateUrl: 'place-full-page.component.html',
})
export class PlaceFullPageComponent implements OnInit {
  public place?: PlaceDto | null;
  public nearestPlaces?: NearestPlaceDto[];
  public activities?: ActivityPreviewDto[];
  public similarPlaces?: PlacePreviewDto[];
  public connectedRoutes?: RoutePreviewDto[];
  public region?: RegionDto;
  public weather?: PlaceFullWeatherDto;
  public isScheduleExpand = false;
  public isHistoryExpand = false;
  public isDescriptionOpen = false;
  public facts: string[] = [];
  public reviewsData: TripAdvisorReviewsListDto;

  @ViewChild(PlacesMapComponent)
  private mapComponent: PlacesMapComponent;

  @ViewChild(AiChatComponent)
  private aiChatComponent: AiChatComponent;

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly regionsApiService: RegionsApiService,
    private readonly activitiesApiService: ActivitiesApiService,
    private readonly routesApiService: RoutesApiService,
    private route: ActivatedRoute,
    private readonly reviewsApiService: ReviewsApiService,
    public readonly apiHelper: ApiHelper,
    public readonly currencyManager: CurrencyManager,
    private titleService: Title,
    private metaService: Meta,
  ) {
    titleService.setTitle('Riyado - Place Information');
  }

  public getFacts() {
    return this.facts.map((fact) => fact.trim().replace(/\.$/, ''));
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.place || !this.region) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: this.region.name,
        url: `/regions/${this.region.index}`,
      },
      {
        label: 'Places',
        url: `/places`,
        qs: {
          regions: [this.region.id],
        },
      },
      {
        label: this.place.name,
        url: `/places/${this.place.index}`,
      },
    ];
  }

  get media(): IGalleryFile[] | undefined {
    return this.place?.files.map((f) => ({
      url: f.originalUrl,
      mediumUrl: f.mediumSizeUrl,
      thumbUrl: f.smallSizeUrl,
    }));
  }

  ngOnInit(): void {
    this.updateData();

    if (this.apiHelper.isBrowser) {
      this.route.paramMap.subscribe(() => {
        this.updateData();
      });
    }
  }

  openAiChat() {
    this.aiChatComponent.open();
  }

  public get placeWorkingTime(): {
    hours: PlaceDailyOpenHoursDto;
    dayName: string;
  }[] {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    const schedule = this.place
      ? Object.entries(this.place.workingTime).map(([key, v]) => ({
          hours: v,
          dayName: upperFirst(key),
          dayIndex: days.indexOf(upperFirst(key)),
        }))
      : [];
    return sortBy(schedule, 'dayIndex');
  }

  public renderMap() {
    this.mapComponent.mapboxElement.onMapLoad(() => {
      if (this.place) {
        this.mapComponent.renderPlaces([
          {
            data: {
              ...this.place,
              preview: this.place.files[0],
            },
            highlight: true,
          },
          ...(this.nearestPlaces?.map((p) => ({
            data: p.place,
            distance: p.distance,
          })) || []),
        ]);

        setTimeout(() => {
          this.highlightItemOnMap(this.place!.index, true);
        }, 0);
      }
    });
  }

  public highlightItemOnMap(placeIndex: string, isActive = true) {
    if (isActive) {
      this.mapComponent.closePopup();
      this.mapComponent.moveMapToItem(placeIndex, true);
    }
  }

  private generatePageMeta(data: PlaceDto) {
    const title = `Riyado - ${data.name}`;

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: getMainPictureUrl(data.files, 'small'),
    });

    if (data.seoDescription) {
      this.metaService.updateTag({
        name: 'description',
        content: data.seoDescription,
      });
      this.metaService.updateTag({
        name: 'og:description',
        content: data.seoDescription,
      });
    }

    if (data.seoKeywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: data.seoKeywords,
      });
    }
  }

  public updateData() {
    const params = this.route.snapshot.paramMap;

    const index = String(params.get('index'));

    this.placesApiService.getPlaceByIndex({ index }).subscribe({
      error: (err) => {
        if (err && err.status === 404) {
          this.place = null;
        } else {
          throw err;
        }
      },
      next: (data) => {
        this.place = data;
        this.facts = compact(data.facts);

        this.generatePageMeta(data);

        if (this.place.tripadvisorLocationId && this.apiHelper.isBrowser) {
          this.reviewsApiService
            .getTripadvisorReviews({
              locationId: this.place.tripadvisorLocationId,
            })
            .subscribe((data) => {
              this.reviewsData = data;
            });
        }

        this.activitiesApiService
          .searchByPlace({
            placeId: this.place.id,
            page: 1,
            limit: 8,
          })
          .subscribe((data) => {
            this.activities = data.items;
          });

        this.regionsApiService
          .getRegionById({ id: data.regionId })
          .subscribe((data) => {
            this.region = data;
          });

        this.routesApiService
          .getConnectedToPlace({
            placeId: data.id,
          })
          .subscribe((data) => {
            this.connectedRoutes = data;
          });

        this.placesApiService
          .getNearestPlaces({ index: data.index, limit: 15 })
          .subscribe((data) => {
            this.nearestPlaces = data;

            setTimeout(() => {
              this.renderMap();
            }, 0);
          });

        this.placesApiService
          .getSimilarPlaces({ index: data.index, limit: 15 })
          .subscribe((data) => {
            this.similarPlaces = data;
          });

        this.placesApiService
          .getPlaceWeather({ index: data.index })
          .subscribe((data) => {
            this.weather = data;
          });
      },
    });
  }

  protected readonly getActivityName = getActivityName;
  protected readonly getActivityIcon = getActivityIcon;
  protected readonly formatMinutes = formatMinutes;
  protected readonly getCurrentWorkingTime = getCurrentWorkingTime;
  protected readonly isOpenNow = isOpenNow;
  protected readonly formatDistance = formatDistance;
  protected readonly formatWorkingTime = formatWorkingTime;
  protected readonly formatClosedType = formatClosedType;
}
