import { Component, OnInit } from '@angular/core';
import {
  PlaceCollectionDto,
  PlaceCollectionsApiService,
  PlacePreviewDto,
  PlacesApiService,
} from '../../core/api/generated/riyado-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { uniq } from 'lodash';
import { getMainPictureUrl } from '../places.utils';
import { ActivatedRoute } from '@angular/router';
import { ApiHelper } from '../../core/api/api.helper';
import { Meta, Title } from '@angular/platform-browser';

dayjs.extend(utc);

@Component({
  selector: 'app-place-collection-fill-page',
  styleUrls: ['place-collection-full-page.component.scss'],
  templateUrl: 'place-collection-full-page.component.html',
})
export class PlaceCollectionFullPageComponent implements OnInit {
  public places: PlacePreviewDto[];
  public collection: PlaceCollectionDto;
  public collections: PlaceCollectionDto[];
  public isDescriptionOpen = false;

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly placeCollectionsApiService: PlaceCollectionsApiService,
    private route: ActivatedRoute,
    private apiHelper: ApiHelper,
    private titleService: Title,
    private metaService: Meta,
  ) {
    titleService.setTitle('Riyado - Collection Information');
  }

  ngOnInit() {
    this.updateData();

    if (this.apiHelper.isBrowser) {
      this.route.paramMap.subscribe(() => {
        this.updateData();
      });
    }
  }

  private generatePageMeta(data: PlaceCollectionDto) {
    const title = `Riyado - ${data.name} Collection`;

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: getMainPictureUrl([data.file], 'small'),
    });

    if (data.seoDescription) {
      this.metaService.updateTag({
        name: 'description',
        content: data.seoDescription,
      });
      this.metaService.updateTag({
        name: 'og:description',
        content: data.seoDescription,
      });
    }

    if (data.seoKeywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: data.seoKeywords,
      });
    }
  }

  private updateData() {
    const params = this.route.snapshot.paramMap;

    const index = String(params.get('index'));

    this.placeCollectionsApiService
      .getPlaceCollectionByIndex({
        index,
      })
      .subscribe((data) => {
        this.collection = data;

        this.generatePageMeta(data);

        const placeIds = uniq(data.placeIds);

        this.placesApiService
          .getPlacesPreview({
            ids: placeIds,
          })
          .subscribe((data) => {
            this.places = data;
          });
      });

    this.placeCollectionsApiService
      .getPlaceCollections({
        limit: 10,
      })
      .subscribe((data) => {
        this.collections = data.filter((c) => c.index !== index);
      });
  }

  protected readonly getMainPictureUrl = getMainPictureUrl;
}
