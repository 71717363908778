import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-page',
  styleUrls: ['terms-page.component.scss'],
  templateUrl: 'terms-page.component.html',
})
export class TermsPageComponent {
  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) {
    titleService.setTitle('Riyado - Terms');

    metaService.updateTag({
      name: 'description',
      content:
        'Review the terms and conditions for using Riyado’s services across the UAE. Understand your rights, responsibilities, and the guidelines for accessing and using our platform in Dubai, Abu Dhabi, and other emirates.',
    });
    metaService.updateTag({
      name: 'keywords',
      content:
        'Riyado terms and conditions, service terms UAE, user agreement UAE, Riyado platform guidelines, legal terms UAE, Riyado service, terms of use UAE, Dubai terms, Abu Dhabi terms, Riyado user responsibilities',
    });
  }
}
