/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getMaxPlacesCost } from '../fn/places/get-max-places-cost';
import { GetMaxPlacesCost$Params } from '../fn/places/get-max-places-cost';
import { getNearestPlaces } from '../fn/places/get-nearest-places';
import { GetNearestPlaces$Params } from '../fn/places/get-nearest-places';
import { getPlaceByIndex } from '../fn/places/get-place-by-index';
import { GetPlaceByIndex$Params } from '../fn/places/get-place-by-index';
import { getPlaces } from '../fn/places/get-places';
import { GetPlaces$Params } from '../fn/places/get-places';
import { getPlacesPreview } from '../fn/places/get-places-preview';
import { GetPlacesPreview$Params } from '../fn/places/get-places-preview';
import { getPlaceWeather } from '../fn/places/get-place-weather';
import { GetPlaceWeather$Params } from '../fn/places/get-place-weather';
import { getSimilarPlaces } from '../fn/places/get-similar-places';
import { GetSimilarPlaces$Params } from '../fn/places/get-similar-places';
import { NearestPlaceDto } from '../models/nearest-place-dto';
import { PlaceDto } from '../models/place-dto';
import { PlaceFullWeatherDto } from '../models/place-full-weather-dto';
import { PlacePreviewDto } from '../models/place-preview-dto';
import { PlacesMaxCostDto } from '../models/places-max-cost-dto';
import { PlacesPaginatedResponseDto } from '../models/places-paginated-response-dto';
import { PlacesPreviewPaginatedResponseDto } from '../models/places-preview-paginated-response-dto';
import { searchPlaces } from '../fn/places/search-places';
import { SearchPlaces$Params } from '../fn/places/search-places';
import { searchPlacesPreview } from '../fn/places/search-places-preview';
import { SearchPlacesPreview$Params } from '../fn/places/search-places-preview';

@Injectable({ providedIn: 'root' })
export class PlacesApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPlaces()` */
  static readonly GetPlacesPath = '/api/v1/places';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlaces()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaces$Response(params?: GetPlaces$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PlaceDto>>> {
    return getPlaces(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlaces$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaces(params?: GetPlaces$Params, context?: HttpContext): Observable<Array<PlaceDto>> {
    return this.getPlaces$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlaceDto>>): Array<PlaceDto> => r.body)
    );
  }

  /** Path part for operation `getPlacesPreview()` */
  static readonly GetPlacesPreviewPath = '/api/v1/places/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlacesPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlacesPreview$Response(params?: GetPlacesPreview$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PlacePreviewDto>>> {
    return getPlacesPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlacesPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlacesPreview(params?: GetPlacesPreview$Params, context?: HttpContext): Observable<Array<PlacePreviewDto>> {
    return this.getPlacesPreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlacePreviewDto>>): Array<PlacePreviewDto> => r.body)
    );
  }

  /** Path part for operation `getMaxPlacesCost()` */
  static readonly GetMaxPlacesCostPath = '/api/v1/places/maxCost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaxPlacesCost()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaxPlacesCost$Response(params?: GetMaxPlacesCost$Params, context?: HttpContext): Observable<StrictHttpResponse<PlacesMaxCostDto>> {
    return getMaxPlacesCost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaxPlacesCost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaxPlacesCost(params?: GetMaxPlacesCost$Params, context?: HttpContext): Observable<PlacesMaxCostDto> {
    return this.getMaxPlacesCost$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlacesMaxCostDto>): PlacesMaxCostDto => r.body)
    );
  }

  /** Path part for operation `searchPlaces()` */
  static readonly SearchPlacesPath = '/api/v1/places/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchPlaces()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchPlaces$Response(params?: SearchPlaces$Params, context?: HttpContext): Observable<StrictHttpResponse<PlacesPaginatedResponseDto>> {
    return searchPlaces(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchPlaces$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchPlaces(params?: SearchPlaces$Params, context?: HttpContext): Observable<PlacesPaginatedResponseDto> {
    return this.searchPlaces$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlacesPaginatedResponseDto>): PlacesPaginatedResponseDto => r.body)
    );
  }

  /** Path part for operation `searchPlacesPreview()` */
  static readonly SearchPlacesPreviewPath = '/api/v1/places/search/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchPlacesPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchPlacesPreview$Response(params?: SearchPlacesPreview$Params, context?: HttpContext): Observable<StrictHttpResponse<PlacesPreviewPaginatedResponseDto>> {
    return searchPlacesPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchPlacesPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchPlacesPreview(params?: SearchPlacesPreview$Params, context?: HttpContext): Observable<PlacesPreviewPaginatedResponseDto> {
    return this.searchPlacesPreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlacesPreviewPaginatedResponseDto>): PlacesPreviewPaginatedResponseDto => r.body)
    );
  }

  /** Path part for operation `getPlaceWeather()` */
  static readonly GetPlaceWeatherPath = '/api/v1/places/{index}/weather';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlaceWeather()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceWeather$Response(params: GetPlaceWeather$Params, context?: HttpContext): Observable<StrictHttpResponse<PlaceFullWeatherDto>> {
    return getPlaceWeather(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlaceWeather$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceWeather(params: GetPlaceWeather$Params, context?: HttpContext): Observable<PlaceFullWeatherDto> {
    return this.getPlaceWeather$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlaceFullWeatherDto>): PlaceFullWeatherDto => r.body)
    );
  }

  /** Path part for operation `getPlaceByIndex()` */
  static readonly GetPlaceByIndexPath = '/api/v1/places/{index}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlaceByIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceByIndex$Response(params: GetPlaceByIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<PlaceDto>> {
    return getPlaceByIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlaceByIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceByIndex(params: GetPlaceByIndex$Params, context?: HttpContext): Observable<PlaceDto> {
    return this.getPlaceByIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlaceDto>): PlaceDto => r.body)
    );
  }

  /** Path part for operation `getNearestPlaces()` */
  static readonly GetNearestPlacesPath = '/api/v1/places/nearest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNearestPlaces()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNearestPlaces$Response(params: GetNearestPlaces$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NearestPlaceDto>>> {
    return getNearestPlaces(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNearestPlaces$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNearestPlaces(params: GetNearestPlaces$Params, context?: HttpContext): Observable<Array<NearestPlaceDto>> {
    return this.getNearestPlaces$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NearestPlaceDto>>): Array<NearestPlaceDto> => r.body)
    );
  }

  /** Path part for operation `getSimilarPlaces()` */
  static readonly GetSimilarPlacesPath = '/api/v1/places/similar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimilarPlaces()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimilarPlaces$Response(params: GetSimilarPlaces$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PlacePreviewDto>>> {
    return getSimilarPlaces(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSimilarPlaces$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimilarPlaces(params: GetSimilarPlaces$Params, context?: HttpContext): Observable<Array<PlacePreviewDto>> {
    return this.getSimilarPlaces$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlacePreviewDto>>): Array<PlacePreviewDto> => r.body)
    );
  }

}
