<div id="regions">
  <div class="header">
    <div class="content-wrap">
      <div class="title-block">
        <h1 class="title">Browse by region</h1>
        <h2 class="subtitle">Find the best spots in each area</h2>
      </div>
    </div>
  </div>

  <div class="description content-wrap">
    <div class="value">
      Discover the unique charm of each region in the UAE. From bustling
      cityscapes to serene desert landscapes, find the best places, activities,
      and events tailored to your interests. Dive in and start your adventure
      today!
    </div>
  </div>

  <div class="content-wrap" *ngIf="!regions">
    <app-loader
      [animation]="false"
      width="100%"
      height="150px"
      marginTop="20px"
      [count]="2"></app-loader>
  </div>

  <div class="regions-list content-wrap">
    <a
      *ngFor="let region of regions"
      class="region"
      routerLink="/regions/{{ region.index }}">
      <div class="img">
        <img
          [ngSrc]="getMainPictureUrl([region.file], 'medium')"
          fill
          [alt]="region.name" />
      </div>
      <h3 class="title">{{ region.name }}</h3>
    </a>
  </div>
</div>
