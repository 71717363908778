<app-not-found *ngIf="activity === null"></app-not-found>

<div id="activity_card" class="content-wrap" *ngIf="activity !== null">
  <app-breadcrumb [items]="getBreadcrumbs()"></app-breadcrumb>

  <div class="activity-title-block">
    <div class="activity-title">
      <app-loader
        *ngIf="!activity"
        [animation]="false"
        width="300px"
        height="20px"></app-loader>

      <h1 class="value">{{ activity?.title }}</h1>
    </div>
  </div>

  <div class="subtitle-block">
    <div class="activity-rating-block" *ngIf="activity">
      <div class="left">
        <div class="rating" *ngIf="activity.reviewRating">
          <ng-icon name="matStarOutline"></ng-icon>
          <div class="value">{{ activity.reviewRating!.toFixed(1) }}</div>
          <div class="count">({{ activity.reviewCount }} reviews)</div>
        </div>

        <div class="supplier" *ngIf="activity.supplier.name">
          <ng-icon name="bootstrapPerson"></ng-icon>
          <div class="value">
            {{ activity.supplier.name }}
          </div>
        </div>

        <div class="destinations">
          <ng-icon name="matLocationOnOutline"></ng-icon>
          <div class="value">
            {{ getActivityDestinations(activity) }}
          </div>
        </div>
      </div>
    </div>

    <div class="actions" *ngIf="activity">
      <app-share></app-share>

      <app-suggestion
        title="Leave suggestion for {{ activity.title }}"
        entityId="{{ activity.code }}"
        entityType="Activity"></app-suggestion>
    </div>
  </div>

  <div class="activity-media-block">
    <div class="loading-block" *ngIf="!activity">
      <div class="left">
        <app-loader [animation]="false" width="100%" height="100%"></app-loader>
      </div>

      <div class="right">
        <div class="line">
          <app-loader
            [animation]="false"
            width="100%"
            height="100%"></app-loader>
        </div>

        <div class="line">
          <app-loader
            [animation]="false"
            width="100%"
            height="100%"></app-loader>
        </div>
      </div>
    </div>

    <app-gallery *ngIf="media" [files]="media"></app-gallery>
  </div>

  <div class="activity-content" *ngIf="activity">
    <div class="availability">
      <div class="price">
        From {{ currencyManager.format(activity.fromPriceAed) }}
      </div>

      <div class="labels">
        <div class="label" *ngIf="activity.itinerary?.duration">
          <ng-icon name="matScheduleOutline"></ng-icon>
          <div class="item-value">
            {{ formatActivityDuration(activity.itinerary!.duration!) }}
          </div>
        </div>

        <div
          class="label"
          *ngIf="activity.cancellationPolicy.type === 'STANDARD'">
          <ng-icon name="matFreeCancellationOutline"></ng-icon>

          <div class="item-value">Free cancellation</div>
        </div>

        <div class="label" *ngIf="isActivityPickupIncluded(activity)">
          <ng-icon name="bootstrapCarFrontFill"></ng-icon>

          <div class="item-value">Pickup included</div>
        </div>
      </div>

      <app-activity-check-availability
        [activity]="activity"></app-activity-check-availability>

      <div class="availability-info">
        <div
          class="cancellation"
          *ngIf="activity.cancellationPolicy?.description">
          <ng-icon name="bootstrapCheckCircleFill"></ng-icon>
          <div>
            <b>Cancellation:</b> {{ activity.cancellationPolicy.description }}
          </div>
        </div>

        <div class="pickup" *ngIf="activity.logistics?.pickup?.additionalInfo">
          <ng-icon name="bootstrapCheckCircleFill"></ng-icon>
          <div>
            <b>Pickup:</b> {{ activity.logistics.pickup!.additionalInfo }}
          </div>
        </div>
      </div>
    </div>

    <div class="description-block" [class.open]="isDescriptionOpen">
      <h3 class="title">Description</h3>

      <div class="html-content" [innerHTML]="activity.description"></div>

      <div class="show-all-button" (click)="isDescriptionOpen = true">
        Read more <ng-icon name="matKeyboardArrowDownOutline"></ng-icon>
      </div>
    </div>

    <div
      class="included-block"
      [class.open]="isIncludedOpen"
      *ngIf="
        (activity?.inclusions && (activity?.inclusions)!.length > 0) ||
        (activity?.exclusions && (activity?.exclusions)!.length > 0)
      ">
      <h3 class="title">What's included</h3>

      <div class="included-content">
        <div class="inclusions">
          <div class="item" *ngFor="let item of getInclusions()">
            <ng-icon name="bootstrapCheck2Circle"></ng-icon>
            <div>
              {{
                item.description ||
                  item.otherDescription ||
                  item.typeDescription ||
                  item.categoryDescription
              }}
            </div>
          </div>
        </div>

        <div class="exclusions">
          <div class="item" *ngFor="let item of getExclusions()">
            <ng-icon name="bootstrapDashCircle"></ng-icon>
            {{
              item.description ||
                item.otherDescription ||
                item.typeDescription ||
                item.categoryDescription
            }}
          </div>
        </div>
      </div>

      <div
        class="show-all-button"
        (click)="isIncludedOpen = true"
        *ngIf="
          activity.inclusions.length > 5 || activity.exclusions.length > 5
        ">
        Read more <ng-icon name="matKeyboardArrowDownOutline"></ng-icon>
      </div>
    </div>

    <div
      class="itinerary-block"
      *ngIf="
        getItineraryItems().length > 0 ||
        activity?.itinerary?.foodMenus ||
        activity?.itinerary?.routes ||
        activity?.itinerary?.days
      ">
      <h3 class="title">What to expect</h3>

      <div
        class="itinerary-menu"
        [class.open]="isMenuOpen"
        *ngIf="activity?.itinerary?.foodMenus">
        <div class="subtitle">Food menu</div>

        <div class="menu-items">
          <div class="item" *ngFor="let item of activity.itinerary!.foodMenus!">
            <div class="name">{{ item.dishName }}</div>
            <div class="course">{{ formatDishType(item.course) }}</div>
            <div class="description" *ngIf="item.dishDescription">
              {{ item.dishDescription }}
            </div>
          </div>
        </div>

        <div
          class="show-all-button"
          (click)="isMenuOpen = true"
          *ngIf="activity.itinerary!.foodMenus!.length > 3">
          Show more <ng-icon name="matKeyboardArrowDownOutline"></ng-icon>
        </div>
      </div>

      <div class="itinerary-items" *ngIf="this.activity?.itinerary?.items">
        <div
          class="item"
          *ngFor="let item of getItineraryItems(); let i = index">
          <div class="counter">{{ i + 1 }}</div>
          <div class="name">{{ item.locationData.name }}</div>
          <div class="description">{{ item.description }}</div>
          <div class="additional">
            <div class="item" *ngIf="item.duration">
              {{ formatActivityDuration(item.duration) }}
            </div>
            <div class="item" *ngIf="item.admissionIncluded">
              Admission ticket included
            </div>
            <div class="item" *ngIf="item.passByWithoutStopping">
              Pass by without stopping
            </div>
          </div>
        </div>
      </div>

      <div class="itinerary-days" *ngIf="this.activity?.itinerary?.days">
        <div
          class="item"
          *ngFor="let item of this.activity.itinerary!.days!; let i = index">
          <div class="counter">Day {{ i + 1 }}</div>
          <div class="name">{{ item.title }}</div>

          <div class="meta">
            <div class="stops-count" *ngIf="item.items">
              {{ pluralize('stop', item.items.length, true) }}
            </div>
          </div>

          <div class="accommodations" *ngIf="item.accommodations">
            <b>Accommodations:</b> {{ item.accommodations }}
          </div>

          <div class="stops" *ngIf="item.items">
            <div class="item" *ngFor="let stop of item.items; let i = index">
              <div class="counter">{{ i + 1 }}</div>

              <div class="location-name">
                {{
                  locationRefsMap.get(stop.locationRef)?.name ||
                    'Unknown location'
                }}
              </div>
              <div
                class="location-address"
                *ngIf="
                  locationRefsMap.get(stop.locationRef)?.unstructuredAddress
                ">
                {{ locationRefsMap.get(stop.locationRef)?.unstructuredAddress }}
              </div>
              <div class="description" *ngIf="stop.description">
                {{ stop.description }}
              </div>

              <div class="meta">
                <div class="duration" *ngIf="stop.duration">
                  {{ formatActivityDuration(stop.duration) }}
                </div>

                <div class="admission">
                  Admission ticket
                  {{ stop.admissionIncluded ? 'Free' : 'not included' }}
                </div>

                <div class="pass-by" *ngIf="stop.passByWithoutStopping">
                  Pass by without stopping
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="itinerary-routes" *ngIf="this.activity?.itinerary?.routes">
        <div
          class="item"
          *ngFor="let item of this.activity.itinerary!.routes!; let i = index">
          <div class="counter">{{ i + 1 }}</div>
          <div class="name">{{ item.name }}</div>

          <div class="meta">
            <div class="duration" *ngIf="item.duration">
              {{ formatActivityDuration(item.duration) }}
            </div>

            <div class="stops-count" *ngIf="item.stops">
              {{ pluralize('stop', item.stops.length, true) }}
            </div>

            <div class="poi-count" *ngIf="item.poi">
              {{ item.poi.length }} {{ pluralize('point', item.poi.length) }} of
              interest
            </div>
          </div>

          <div class="schedule">
            <b>Schedule:</b> {{ item.operatingSchedule }}
          </div>

          <div class="stops" *ngIf="item.stops">
            <div class="item" *ngFor="let stop of item.stops; let i = index">
              <div class="counter">{{ i + 1 }}</div>

              <div class="location-name">
                {{
                  locationRefsMap.get(stop.locationRef)?.name ||
                    'Unknown location'
                }}
              </div>
              <div
                class="location-address"
                *ngIf="
                  locationRefsMap.get(stop.locationRef)?.unstructuredAddress
                ">
                {{ locationRefsMap.get(stop.locationRef)?.unstructuredAddress }}
              </div>
              <div class="description" *ngIf="stop.description">
                {{ stop.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="pickup-block"
      [class.open]="isLocationsOpen"
      *ngIf="
        activity.logistics.pickup ||
        activity.logistics.start ||
        activity.logistics.end
      ">
      <h3 class="title">Meeting and Pickup</h3>

      <div
        class="pickup-content"
        *ngIf="
          activity.logistics.pickup &&
          activity.logistics.pickup.locations.length > 0
        ">
        <div class="locations">
          Pickup from {{ getPickupLocationsAmount() }} locations
        </div>

        <div
          class="description"
          *ngIf="activity.logistics.pickup.additionalInfo">
          {{ activity.logistics.pickup.additionalInfo }}
        </div>
      </div>

      <div
        class="pickup-content"
        *ngIf="
          activity.logistics.pickup &&
          activity.logistics.pickup.locations.length === 0
        ">
        <div class="custom-pickup" *ngIf="activity.itinerary?.routes">
          You may start this tour at any of the stops listed.
        </div>
      </div>

      <div
        class="redemption"
        *ngIf="activity.logistics.redemption.specialInstructions">
        {{ activity.logistics.redemption.specialInstructions }}
      </div>

      <div
        class="meeting-content"
        *ngIf="
          (activity.logistics.start && activity.logistics.start.length > 0) ||
          (activity.logistics.end && activity.logistics.end.length > 0)
        ">
        <div class="start">
          <div class="title">
            <ng-icon name="matLocationOnOutline"></ng-icon>
            Start point
            <div
              class="few-locations-prompt"
              *ngIf="
                activity.logistics.start && activity.logistics.start.length > 1
              ">
              - several locations
            </div>
          </div>

          <div class="empty" *ngIf="!activity.logistics.start">
            There is no start point
          </div>

          <div class="meeting-value" *ngIf="activity.logistics.start">
            <div
              class="location"
              *ngFor="let location of activity.logistics.start">
              <div class="name">
                {{ locationRefsMap.get(location.locationRef)?.name }}
              </div>
              <div
                class="address"
                *ngIf="
                  locationRefsMap.get(location.locationRef)?.unstructuredAddress
                ">
                {{
                  locationRefsMap.get(location.locationRef)?.unstructuredAddress
                }}
              </div>

              <div class="description">
                {{ location.description }}
              </div>

              <a
                class="google"
                *ngIf="locationRefsMap.get(location.locationRef)?.center"
                href="https://www.google.com/maps?q={{
                  locationRefsMap.get(location.locationRef)!.center!.lat
                }},{{ locationRefsMap.get(location.locationRef)!.center!.lon }}"
                target="_blank">
                Open in google maps
              </a>
            </div>
          </div>
        </div>

        <div class="end">
          <div class="title">
            <ng-icon name="letsFlagFinish"></ng-icon>
            End point
            <div
              class="few-locations-prompt"
              *ngIf="
                activity.logistics.end && activity.logistics.end.length > 1
              ">
              - several locations
            </div>
          </div>

          <div class="empty" *ngIf="!activity.logistics.end">
            There is no end point
          </div>

          <div class="meeting-value" *ngIf="activity.logistics.end">
            <div
              class="location"
              *ngFor="let location of activity.logistics.end">
              <div class="name">
                {{ locationRefsMap.get(location.locationRef)?.name }}
              </div>
              <div
                class="address"
                *ngIf="
                  locationRefsMap.get(location.locationRef)?.unstructuredAddress
                ">
                {{
                  locationRefsMap.get(location.locationRef)?.unstructuredAddress
                }}
              </div>

              <div class="description">
                {{ location.description }}
              </div>

              <a
                class="google"
                *ngIf="locationRefsMap.get(location.locationRef)?.center"
                href="https://www.google.com/maps?q={{
                  locationRefsMap.get(location.locationRef)!.center!.lat
                }},{{ locationRefsMap.get(location.locationRef)!.center!.lon }}"
                target="_blank">
                Open in google maps
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        class="show-all-button"
        (click)="isLocationsOpen = true"
        *ngIf="
          (activity.logistics.start || []).length > 3 ||
          (activity.logistics.end || []).length > 3
        ">
        Show more <ng-icon name="matKeyboardArrowDownOutline"></ng-icon>
      </div>
    </div>

    <div
      class="additional-block"
      [class.open]="isAdditionalOpen"
      *ngIf="
        activity?.additionalInfo && (activity?.additionalInfo)!.length > 0
      ">
      <h3 class="title">Additional info</h3>

      <ul class="additional-content">
        <li *ngFor="let item of getAdditional()">
          {{ item.description }}
        </li>
      </ul>

      <div
        class="show-all-button"
        (click)="isAdditionalOpen = true"
        *ngIf="activity.additionalInfo.length > 6">
        Read more <ng-icon name="matKeyboardArrowDownOutline"></ng-icon>
      </div>
    </div>

    <div class="cancellation-block">
      <h3 class="title">Cancellation policy</h3>

      <div class="cancellation-content">
        <div class="type">
          {{ formatCancellationPolicy(activity.cancellationPolicy) }}
        </div>

        <div
          class="description"
          *ngIf="activity.cancellationPolicy.description">
          {{ activity.cancellationPolicy.description }}
        </div>

        <div class="labels">
          <div
            class="label"
            *ngIf="activity.cancellationPolicy.cancelIfBadWeather">
            <ng-icon name="bootstrapCloudRainFill"></ng-icon>
            <div class="item-value">Cancel if bad weather</div>
          </div>

          <div
            class="label"
            *ngIf="activity.cancellationPolicy.cancelIfInsufficientTravelers">
            <ng-icon name="bootstrapPeople"></ng-icon>
            <div class="item-value">Cancel if not enough travelers</div>
          </div>
        </div>
      </div>
    </div>

    <div class="similar-block">
      <app-entities-preview-list
        title="Similar activities"
        [nowrap]="true"
        [newTab]="true"
        [activities]="similarActivities"></app-entities-preview-list>
    </div>
  </div>
</div>
