<div id="collections_list">
  <div class="header">
    <div class="content-wrap">
      <div class="title-block">
        <h1 class="title">Our Best Collections</h1>
        <h3 class="subtitle">
          Discover Hidden Gems and Thrilling Places in Our UAE Collections
        </h3>
      </div>
    </div>
  </div>

  <div class="description content-wrap">
    <div class="value">
      Discover our curated combinations of captivating places in the UAE.
      Whether you seek adventure, relaxation, or cultural experiences, our
      collections have something special for you. Find hidden gems, iconic
      landmarks, and unforgettable moments in one of the world’s most vibrant
      destinations. Start planning your perfect UAE journey today!
    </div>
  </div>

  <div class="content-wrap" *ngIf="!collections">
    <app-loader
      [animation]="false"
      width="100%"
      height="100px"
      marginTop="30px"></app-loader>
  </div>

  <div class="list content-wrap" *ngIf="collections">
    <app-place-collection-preview
      *ngFor="let c of collections"
      [horizontal]="isHorizontal"
      [newTab]="true"
      [collection]="c"></app-place-collection-preview>
  </div>
</div>
