import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  ActivityPreviewDto,
  PlaceCollectionDto,
  PlacePreviewDto,
  RegionDto,
  RoutePreviewDto,
} from '../api/generated/riyado-api';
import { getMainPictureUrl } from '../../places/places.utils';
import { ApiHelper } from '../api/api.helper';

dayjs.extend(utc);

@Component({
  selector: 'app-entities-preview-list',
  styleUrls: ['entities-preview-list.component.scss'],
  templateUrl: 'entities-preview-list.component.html',
})
export class EntitiesPreviewListComponent {
  @Input()
  public nowrap: boolean;

  @Input()
  public title: string;

  @Input()
  public subtitle?: string;

  @Input()
  public queryParams?: Record<string, any>;

  @Input()
  public routerLinkValue?: string;

  @Input()
  public places?: PlacePreviewDto[];

  @Input()
  public activities?: ActivityPreviewDto[];

  @Input()
  public routes?: RoutePreviewDto[];

  @Input()
  public regions?: RegionDto[];

  @Input()
  public collections?: PlaceCollectionDto[];

  @Input()
  public newTab = false;

  @ViewChild('entitiesBody')
  public entitiesBody: ElementRef;

  public isScrolled = false;

  constructor(public apiHelper: ApiHelper) {}

  public get entities() {
    return (
      this.places ||
      this.routes ||
      this.regions ||
      this.collections ||
      this.activities ||
      []
    );
  }

  public isLoad() {
    return (
      !this.places &&
      !this.routes &&
      !this.regions &&
      !this.collections &&
      !this.activities
    );
  }

  public isEmpty() {
    return this.entities.length === 0;
  }

  protected readonly getMainPictureUrl = getMainPictureUrl;

  // ngAfterViewInit() {
  //   if (this.apiHelper.isBrowser) {
  //     const block =
  //       this.entitiesBody.nativeElement.querySelector('.entities-list');
  //
  //     if (block) {
  //       block.addEventListener('scroll', () => {
  //         if (block.scrollLeft > 0) {
  //           this.isScrolled = true;
  //         } else {
  //           this.isScrolled = false;
  //         }
  //       });
  //     }
  //   }
  // }
}
