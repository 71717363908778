<div
  class="custom-select"
  appOutsideClick
  (outsideClick)="close()"
  [class.open]="isOpen">
  <div class="label-block" *ngIf="currentItem" (click)="toggle()">
    <ng-icon *ngIf="icon" [name]="icon"></ng-icon>
    <span>{{ title }}: {{ currentItem.label }}</span>
  </div>

  <div class="label-block" *ngIf="!currentItem" (click)="toggle()">
    <ng-icon *ngIf="icon" [name]="icon"></ng-icon>
    <span>{{ title }}</span>
  </div>

  <div #itemsElement class="items">
    <div class="item" *ngFor="let item of items" (click)="setValue(item.value)">
      {{ item.label }}
    </div>
  </div>
</div>
