import { Component, OnInit, ViewChild } from '@angular/core';
import {
  PlacePreviewDto,
  PlacesApiService,
  RegionDto,
  RegionsApiService,
  RoutePreviewDto,
  RoutesApiService,
} from '../../core/api/generated/riyado-api';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { placeActivities } from '../../places/places.types';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';
import { AiChatComponent } from '../../core/ai-chat/ai-chat.component';
import { ApiHelper } from '../../core/api/api.helper';
import { Meta, Title } from '@angular/platform-browser';
import { getMainPictureUrl } from '../../places/places.utils';

dayjs.extend(utc);

@Component({
  selector: 'app-region-full-page',
  styleUrls: ['region.component.scss'],
  templateUrl: 'region.component.html',
})
export class RegionComponent implements OnInit {
  public region?: RegionDto | null;
  public places: PlacePreviewDto[];
  public routes: RoutePreviewDto[];
  public photoSpots: PlacePreviewDto[];
  public totalPlaces: number = 0;
  public totalRoutes: number = 0;

  public isDescriptionOpen = false;

  @ViewChild(AiChatComponent)
  private aiChatComponent: AiChatComponent;

  public placeActivities = [
    placeActivities.Shopping,
    placeActivities.Photo,
    placeActivities.Natural,
    placeActivities.Entertainment,
    placeActivities.Cultural,
    placeActivities.Beaches,
    placeActivities.Trekking,
  ];

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly regionsApiService: RegionsApiService,
    private readonly routesApiService: RoutesApiService,
    private apiHelper: ApiHelper,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
  ) {
    titleService.setTitle('Riyado - Region Information');
  }

  ngOnInit(): void {
    this.updateData();

    if (this.apiHelper.isBrowser) {
      this.route.paramMap.subscribe(() => {
        this.updateData();
      });
    }
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.region) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: 'Regions',
        url: '/regions',
      },
      {
        label: this.region.name,
        url: `/regions/${this.region.index}`,
      },
    ];
  }

  private generatePageMeta(data: RegionDto) {
    const title = `Riyado - ${data.name}`;

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: getMainPictureUrl([data.file], 'small'),
    });

    if (data.seoDescription) {
      this.metaService.updateTag({
        name: 'description',
        content: data.seoDescription,
      });
      this.metaService.updateTag({
        name: 'og:description',
        content: data.seoDescription,
      });
    }

    if (data.seoKeywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: data.seoKeywords,
      });
    }
  }

  public updateData() {
    const params = this.route.snapshot.paramMap;

    const index = String(params.get('index'));

    this.regionsApiService.getRegionByIndex({ index }).subscribe({
      error: (err) => {
        if (err && err.status === 404) {
          this.region = null;
        } else {
          throw err;
        }
      },
      next: (data) => {
        this.region = data;

        this.generatePageMeta(data);

        this.placesApiService
          .searchPlacesPreview({
            regionIds: [data.id],
            limit: 15,
            sortBy: 'Rating',
          })
          .subscribe((data) => {
            this.places = data.items;
            this.totalPlaces = data.totalFilteredItems;
          });

        this.placesApiService
          .getPlacesPreview({
            limit: 15,
            sortBy: 'Rating',
            activities: ['Photo'],
            regionIds: [data.id],
          })
          .subscribe((data) => {
            this.photoSpots = data;
          });

        this.routesApiService
          .searchRoutesPreview({
            regionIds: [data.id],
            limit: 15,
          })
          .subscribe((data) => {
            this.routes = data.items;
            this.totalRoutes = data.totalFilteredItems;
          });
      },
    });
  }

  openAiChat() {
    this.aiChatComponent.open();
  }
}
