import { Component, OnInit } from '@angular/core';
import {
  ActivitiesApiService,
  ActivityDto,
  ActivityItineraryItemDto,
  ActivityLocationRefDto,
  ActivityPreviewDto,
} from '../../core/api/generated/riyado-api';
import { ActivatedRoute } from '@angular/router';
import { IGalleryFile } from '../../core/gallery/gallery.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';
import { ApiHelper } from '../../core/api/api.helper';
import { CurrencyManager } from '../../core/currency-manager';
import { formatActivityDuration } from '../../places/places.utils';
import {
  formatCancellationPolicy,
  formatDishType,
  getActivityDestinations,
  isActivityPickupIncluded,
} from '../activities.utils';
import pluralize from 'pluralize';
import { Meta, Title } from '@angular/platform-browser';
import { trim } from 'lodash';

dayjs.extend(utc);

@Component({
  selector: 'app-activity-full-page',
  styleUrls: ['activity-full-page.component.scss'],
  templateUrl: 'activity-full-page.component.html',
})
export class ActivityFullPageComponent implements OnInit {
  public activity?: ActivityDto | null;
  public similarActivities?: ActivityPreviewDto[];
  public isDescriptionOpen = false;
  public isIncludedOpen = false;
  public isAdditionalOpen = false;
  public isLocationsOpen = false;
  public isMenuOpen = false;
  public locationRefsMap = new Map<string, ActivityLocationRefDto>();

  constructor(
    private readonly activitiesApiService: ActivitiesApiService,
    private route: ActivatedRoute,
    public readonly apiHelper: ApiHelper,
    public readonly currencyManager: CurrencyManager,
    private titleService: Title,
    private metaService: Meta,
  ) {
    titleService.setTitle('Riyado - Activity Information');
  }

  getInclusions() {
    return this.isIncludedOpen
      ? this.activity?.inclusions
      : this.activity?.inclusions.slice(0, 5);
  }

  getExclusions() {
    return this.isIncludedOpen
      ? this.activity?.exclusions
      : this.activity?.exclusions.slice(0, 5);
  }

  getAdditional() {
    return this.isAdditionalOpen
      ? this.activity?.additionalInfo
      : this.activity?.additionalInfo.slice(0, 6);
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.activity) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: 'Activities',
        url: `/activities`,
      },
      {
        label: this.activity.title,
        url: `/activities/${this.activity.code}`,
      },
    ];
  }

  get media(): IGalleryFile[] | undefined {
    return this.activity?.images.map((f) => ({
      url: f.originalUrl,
      mediumUrl: f.smallUrl,
      thumbUrl: f.smallUrl,
    }));
  }

  ngOnInit(): void {
    this.updateData();

    if (this.apiHelper.isBrowser) {
      this.route.paramMap.subscribe(() => {
        this.updateData();
      });
    }
  }

  public getPickupLocationsAmount() {
    const amount = this.activity?.logistics.pickup?.locations.length || 0;
    return amount >= 30 ? 'different' : amount;
  }

  public getItineraryItems(): (ActivityItineraryItemDto & {
    locationData: ActivityLocationRefDto;
  })[] {
    if (!this.activity?.itinerary?.items) {
      return [];
    }

    return <any>(this.activity.itinerary.items
      .map((item) => {
        return {
          ...item,
          locationData: this.locationRefsMap.get(item.locationRef),
        };
      })
      .filter((item) => !!item.locationData) || []);
  }

  private generatePageMeta(data: ActivityDto) {
    const title = `Riyado - ${data.title}`;
    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });
    this.metaService.updateTag({
      name: 'og:image',
      content: data.images[0].smallUrl,
    });

    let metaDescription = `${data.title}. ${data.description.split('.').slice(0, 1).join('')}.`;

    if (data.inclusions?.length > 0) {
      metaDescription += ` Enjoy ${data.inclusions
        .map(
          (inc: any) =>
            inc.description ||
            inc.otherDescription ||
            inc.typeDescription ||
            inc.categoryDescription,
        )
        .filter((v) => !!v)
        .slice(0, 3)
        .join(', ')}`;
    }
    const metaLocation = this.getActivityDestinations(data);

    if (metaLocation) {
      metaDescription += ` Located in ${metaLocation}.`;
    }

    this.metaService.updateTag({
      name: 'description',
      content: metaDescription.replace(/\n/g, ''),
    });
    this.metaService.updateTag({
      name: 'og:description',
      content: metaDescription.replace(/\n/g, ''),
    });

    this.metaService.updateTag({
      name: 'keywords',
      content: `Riyado UAE activities, explore UAE, top activities UAE, adventure in UAE, UAE tourism, ${data.tags.map(trim).join(', ')}`,
    });
  }

  public updateData() {
    const params = this.route.snapshot.paramMap;

    const code = String(params.get('code'));

    this.activitiesApiService.getActivityByCode({ code }).subscribe({
      error: (err) => {
        if (err && err.status === 404) {
          this.activity = null;
        } else {
          throw err;
        }
      },
      next: (data) => {
        this.activity = data;

        this.generatePageMeta(data);

        for (const loc of data.locationRefs) {
          this.locationRefsMap.set(loc.ref, loc);
        }

        this.activitiesApiService
          .getSimilarActivities({ code: data.code, limit: 15 })
          .subscribe((data) => {
            this.similarActivities = data;
          });
      },
    });
  }

  protected readonly formatActivityDuration = formatActivityDuration;
  protected readonly getActivityDestinations = getActivityDestinations;
  protected readonly isActivityPickupIncluded = isActivityPickupIncluded;
  protected readonly formatCancellationPolicy = formatCancellationPolicy;
  protected readonly formatDishType = formatDishType;
  protected readonly pluralize = pluralize;
}
