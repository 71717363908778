import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiHelper } from '../api/api.helper';

@Component({
  selector: 'app-filter-slider',
  templateUrl: 'filter-slider.component.html',
  styleUrl: 'filter-slider.component.scss',
})
export class FilterSliderComponent {
  @Input()
  public min: number;

  @Input()
  public max: number;

  @Input()
  public step: number = 1;

  @Input()
  public valueMin: number = 0;

  @Input()
  public valueMax: number = 0;

  @Output()
  public valueChange = new EventEmitter<number[]>();

  @Input()
  public title: string;

  @Input()
  public name?: string;

  @Input()
  public format?: (val: number) => string;

  constructor(public readonly apiHelper: ApiHelper) {}

  ngOnInit(): void {
    this.valueMin = 0;
    this.valueMax = this.max;
  }

  onChange() {
    this.valueChange.emit([this.valueMin, this.valueMax]);
  }

  clear() {
    this.valueMin = 0;
    this.valueMax = this.max;

    this.onChange();
  }

  translate(value: number): string {
    const formattedValue = this.format ? this.format(value) : String(value);

    return formattedValue;
  }
}
