import { Component, Input } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { RoutePreviewDto } from '../../core/api/generated/riyado-api';
import {
  formatDistance,
  formatMinutes,
  getMainPictureUrl,
} from '../../places/places.utils';
import { getTypeName } from '../routes.utils';

dayjs.extend(utc);

@Component({
  selector: 'app-route-near-by',
  styleUrls: ['route-near-by.component.scss'],
  templateUrl: 'route-near-by.component.html',
})
export class RouteNearByComponent {
  @Input()
  public route: RoutePreviewDto;

  @Input()
  public distance: number;
  protected readonly formatDistance = formatDistance;
  protected readonly formatMinutes = formatMinutes;
  protected readonly getMainPictureUrl = getMainPictureUrl;
  protected readonly getTypeName = getTypeName;
}
