<app-not-found *ngIf="route === null"></app-not-found>

<div id="route_card" class="content-wrap" *ngIf="route !== null">
  <app-breadcrumb [items]="getBreadcrumbs()"></app-breadcrumb>

  <div class="route-title-block">
    <div class="left">
      <div class="route-title">
        <app-loader
          *ngIf="!route"
          [animation]="false"
          width="300px"
          height="30px"></app-loader>

        <h1 class="value">{{ route?.name }}</h1>
      </div>

      <div class="route-type" *ngIf="route">
        <ng-icon [name]="getTypeIcon(route.type!)"></ng-icon>
        <div class="name">{{ getTypeName(route.type!) }}</div>
      </div>
    </div>

    <div class="actions" *ngIf="route">
      <app-share></app-share>

      <app-suggestion
        title="Leave suggestion for {{ route.name }}"
        entityId="{{ route.id }}"
        entityType="Route"></app-suggestion>
    </div>
  </div>

  <div class="route-media-block">
    <div class="loading-block" *ngIf="!route">
      <div class="left">
        <app-loader [animation]="false" width="100%" height="100%"></app-loader>
      </div>

      <div class="right">
        <div class="line">
          <app-loader
            [animation]="false"
            width="100%"
            height="100%"></app-loader>
        </div>

        <div class="line">
          <app-loader
            [animation]="false"
            width="100%"
            height="100%"></app-loader>
        </div>
      </div>
    </div>

    <app-gallery *ngIf="media" [files]="media"></app-gallery>
  </div>

  <div class="route-content">
    <div class="main-info-block">
      <div class="description-block" *ngIf="route">
        <div
          class="description html-content"
          [innerHTML]="route.description"></div>
      </div>

      <div class="widgets">
        <div class="widget shadow-block general-info" *ngIf="route">
          <div class="general-info-item duration-item">
            <ng-icon name="matHourglassTopOutline"></ng-icon>
            <div class="item-value">
              Duration {{ formatMinutes(route.duration) }}
            </div>
          </div>

          <div class="general-info-item distance-item">
            <ng-icon name="matRouteOutline"></ng-icon>
            <div class="item-value">
              Distance {{ formatDistance(route.distance) }}
            </div>
          </div>

          <div
            class="general-info-item places-item"
            *ngIf="places && places.length > 0">
            <ng-icon name="matLocationOnOutline"></ng-icon>
            <div class="item-value">
              Includes {{ places.length }} places:
              <a routerLink="/places/{{ p.index }}" *ngFor="let p of places">{{
                p.name
              }}</a>
            </div>
          </div>
        </div>

        <div class="widget" *ngIf="!route">
          <app-loader
            [animation]="false"
            width="100%"
            height="250px"></app-loader>
        </div>
      </div>
    </div>

    <div class="location-block">
      <div class="widget shadow-block map" *ngIf="route">
        <app-mapbox
          [geocoderEnabled]="false"
          [style]="'streets-v12'"
          [zoom]="11"
          [coordinates]="
            route.location
              ? { lng: route.location.x, lat: route.location.y }
              : null
          "
          [addStartPoint]="false"></app-mapbox>
      </div>

      <div
        class="widget shadow-block nearest-routes"
        *ngIf="nearestRoutes && nearestRoutes.length > 0">
        <div class="title">Nearby Finds:</div>
        <app-route-near-by
          *ngFor="let route of nearestRoutes || []"
          [route]="route.route"
          [distance]="route.distance"></app-route-near-by>
      </div>
    </div>

    <div class="steps-block" *ngIf="route && placesMap">
      <div
        class="step shadow-block"
        *ngFor="let s of route.steps; let i = index">
        <h4 class="step-title">Step {{ i + 1 }}</h4>

        <div class="step-places">
          <app-place-near-by
            *ngFor="let place of getStepPlaces(s)"
            [place]="place"></app-place-near-by>
        </div>
        <div
          class="step-description html-content"
          [innerHTML]="s.description"></div>
      </div>
    </div>
  </div>
</div>
