import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './misc/not-found/not-found.component';
import { PlaceFullPageComponent } from './places/place-full-page/place-full-page.component';
import { PlacesListPageComponent } from './places/places-list-page/places-list-page.component';
import { RoutesListPageComponent } from './routes/routes-list-page/routes-list-page.component';
import { RouteFullPageComponent } from './routes/route-full-page/route-full-page.component';
import { MainComponent } from './main/main.component';
import { PlaceCollectionsPageComponent } from './places/place-collections-page/place-collections-page.component';
import { SearchPageComponent } from './search/search-page.component';
import { PrivacyPageComponent } from './legal/privacy/privacy-page.component';
import { TermsPageComponent } from './legal/terms/terms-page.component';
import { PlaceCollectionFullPageComponent } from './places/place-collection-full-page/place-collection-full-page.component';
import { RegionComponent } from './region/region-full-page/region.component';
import { RegionsListComponent } from './region/regions-page/regions.component';
import { ActivitiesListPageComponent } from './activities/activities-list-page/activities-list-page.component';
import { ActivityFullPageComponent } from './activities/activity-full-page/activity-full-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: MainComponent,
      },
      {
        path: 'privacy',
        component: PrivacyPageComponent,
      },
      {
        path: 'terms',
        component: TermsPageComponent,
      },
      {
        path: 'regions',
        children: [
          {
            path: '',
            component: RegionsListComponent,
          },
          {
            path: ':index',
            component: RegionComponent,
          },
        ],
      },
      {
        path: 'places',
        children: [
          {
            path: '',
            component: PlacesListPageComponent,
          },

          {
            path: ':index',
            component: PlaceFullPageComponent,
          },
        ],
      },
      {
        path: 'collections',
        children: [
          {
            path: '',
            component: PlaceCollectionsPageComponent,
          },
          {
            path: ':index',
            component: PlaceCollectionFullPageComponent,
          },
        ],
      },
      {
        path: 'activities',
        children: [
          {
            path: '',
            component: ActivitiesListPageComponent,
          },
          {
            path: ':code',
            component: ActivityFullPageComponent,
          },
        ],
      },
      {
        path: 'search',
        children: [
          {
            path: '',
            component: SearchPageComponent,
          },
        ],
      },
      {
        path: 'routes',
        children: [
          {
            path: '',
            component: RoutesListPageComponent,
          },
          {
            path: ':index',
            component: RouteFullPageComponent,
          },
        ],
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
