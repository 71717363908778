import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ActivitiesApiService,
  ActivityPreviewDto,
} from '../../core/api/generated/riyado-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FilterCheckboxOption } from '../../core/filter-checkbox/filter-checkbox.component';
import { CurrencyManager } from '../../core/currency-manager';
import { OpeningHoursType } from '../../places/places.types';
import { SearchActivities$Params } from '../../core/api/generated/riyado-api/fn/activities/search-activities';
import { FilterSliderComponent } from '../../core/filter-slider/filter-slider.component';
import { ActivatedRoute } from '@angular/router';
import { formatMinutes } from '../../places/places.utils';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject, takeUntil } from 'rxjs';
import { ListPopoverItem } from '../../core/custom-select/custom-select.component';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

dayjs.extend(utc);

@Component({
  selector: 'app-activities-list-page',
  styleUrls: ['activities-list-page.component.scss'],
  templateUrl: 'activities-list-page.component.html',
})
export class ActivitiesListPageComponent implements OnInit {
  public activities?: ActivityPreviewDto[];
  public totalItems = 0;
  public totalFilteredItems = 0;

  public page = 1;
  public limit = 25;
  public isLoading = false;
  public isFiltersOpen = false;
  public isHorizontal = true;

  private destroy$ = new Subject<void>();

  @ViewChild('costsFilter', { static: true })
  public costsFilterComponent: FilterSliderComponent;

  constructor(
    private readonly activitiesApiService: ActivitiesApiService,
    public readonly currencyManager: CurrencyManager,
    private route: ActivatedRoute,
    private readonly breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.generatePageMeta();
  }

  private generatePageMeta() {
    const title = 'Riyado - Explore Activities';
    const description =
      'Discover exciting activities across the UAE with Riyado. From thrilling desert safaris in Dubai to cultural experiences in Abu Dhabi, explore a wide range of adventures in the popular emirates. Plan your next unforgettable experience with Riyado’s expert recommendations.';
    const keywords =
      'Riyado UAE activities, explore UAE, Dubai desert safari, Abu Dhabi cultural experiences, top activities UAE, adventure in UAE, UAE tourism, popular emirates, Riyado service, things to do UAE, UAE travel guide';

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaService.updateTag({
      name: 'og:description',
      content: description,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: `${environment.dashboardUrl}/assets/logo.png`,
    });

    this.metaService.updateTag({
      name: 'keywords',
      content: keywords,
    });
  }

  public additionalFilterOptions: FilterCheckboxOption[] = [
    {
      label: 'Free cancellation',
      value: 'free-cancellation',
    },
    {
      label: 'Pickup included',
      value: 'pickup-included',
    },
  ];
  public additionalFilter: string[] = [];

  public hoursFilterOptions: FilterCheckboxOption[] = [
    {
      label: 'Morning (7AM-12PM)',
      value: OpeningHoursType.Morning,
    },
    {
      label: 'Afternoon (12PM-5PM)',
      value: OpeningHoursType.Afternoon,
    },
    {
      label: 'Evening (5PM-12AM)',
      value: OpeningHoursType.Evening,
    },
    {
      label: 'Night (12AM-7AM)',
      value: OpeningHoursType.Night,
    },
  ];
  public hoursFilter: OpeningHoursType[] = [];

  public durationFilter: number[] = [];

  public costsFilterAed: number[] = [];
  public maxCostsAed: number = 0;
  public maxCosts: number;

  public ratingFilterOptions: FilterCheckboxOption[] = [
    {
      label: '★ ★ ★ ★ ★',
      value: 5,
    },
    {
      label: '★ ★ ★ ★',
      value: 4,
    },
    {
      label: '★ ★ ★',
      value: 3,
    },
    {
      label: '★ ★',
      value: 2,
    },
    {
      label: '★',
      value: 1,
    },
  ];
  public ratingFilter: number[] = [];

  public sortBy: SearchActivities$Params['sortBy'] = 'Rating';

  public updateSortBy(val: ListPopoverItem) {
    this.sortBy = <SearchActivities$Params['sortBy']>val.value;
    this.onFiltersChange();
  }

  public closeFilters(e: MouseEvent) {
    const barBody = document.querySelector('#filters .filters-body');
    const clickedInside = barBody?.contains(e.target as Node);

    if (!clickedInside) {
      this.isFiltersOpen = false;
    }
  }

  ngOnInit() {
    const initialValue =
      this.breakpointObserver.isMatched('(max-width: 820px)');
    this.isHorizontal = !initialValue;

    this.updateFiltersData();
    this.parseQueryParams();
    this.updateActivities();

    this.currencyManager.currentCurrency$.subscribe(() => {
      const currency = this.currencyManager.getCurrency();

      this.maxCosts = this.currencyManager.convertCurrency(this.maxCostsAed);

      this.costsFilterComponent.valueMin = this.currencyManager.convertCurrency(
        this.costsFilterComponent.valueMin,
        currency === 'AED' ? 'USD' : 'AED',
      );
      this.costsFilterComponent.valueMax = this.currencyManager.convertCurrency(
        this.costsFilterComponent.valueMax,
        currency === 'AED' ? 'USD' : 'AED',
      );
    });

    this.breakpointObserver
      .observe('(max-width: 820px)')
      .subscribe((result) => {
        this.isHorizontal = !result.matches;
      });
  }

  public parseQueryParams() {
    const params = this.route.snapshot.queryParamMap;
    const page = parseInt(String(params.get('page')));
    if (page) {
      this.page = page;
    }
  }

  public updateFiltersData() {
    this.activitiesApiService.getMaxActivitiesCost().subscribe((data) => {
      this.maxCostsAed = data.valueAed;
      this.initCostsFilter();
    });
  }

  public initCostsFilter() {
    this.maxCosts = this.currencyManager.convertCurrency(this.maxCostsAed);
    this.costsFilterComponent.valueMin = 0;
    this.costsFilterComponent.valueMax = this.maxCosts;
  }

  public updateDurationFilter(value: number[]) {
    this.costsFilterAed = value;
    this.onFiltersChange();
  }

  public updateCostsFilter(value: number[]) {
    this.costsFilterAed = [
      this.currencyManager.convertCurrency(
        value[0],
        this.currencyManager.getCurrency(),
        'AED',
      ),
      this.currencyManager.convertCurrency(
        value[1],
        this.currencyManager.getCurrency(),
        'AED',
      ),
    ];
    this.onFiltersChange();
  }

  public formatCosts(val: number) {
    return this.currencyManager.format(val);
  }

  public formatDuration(val: number) {
    return formatMinutes(val);
  }

  public trackBy(index: number, activity: ActivityPreviewDto) {
    return activity.code;
  }

  public onPageChanged(page: number) {
    this.page = page;

    this.updateActivities();
  }

  public onFiltersChange() {
    this.page = 1;
    // this.activities = undefined;

    setTimeout(() => {
      this.updateActivities();
    }, 0);
  }

  public get totalPages() {
    return Math.ceil(this.totalFilteredItems / this.limit) || 1;
  }

  private getFilters(): SearchActivities$Params {
    let startTime, endTime;

    if (this.hoursFilter) {
      const val = this.hoursFilter[0];

      if (val === OpeningHoursType.Morning) {
        startTime = '07:00';
        endTime = '12:00';
      }

      if (val === OpeningHoursType.Afternoon) {
        startTime = '12:00';
        endTime = '17:00';
      }

      if (val === OpeningHoursType.Evening) {
        startTime = '17:00';
        endTime = '23:59';
      }

      if (val === OpeningHoursType.Night) {
        startTime = '00:00';
        endTime = '07:00';
      }
    }

    const isFreeCancellation = !!this.additionalFilter.find(
      (f) => f === 'free-cancellation',
    );

    const isPickupIncluded = !!this.additionalFilter.find(
      (f) => f === 'pickup-included',
    );

    return {
      startTime,
      endTime,
      minMaxDuration: this.durationFilter,
      minMaxPrice: this.costsFilterAed,
      ratings: this.ratingFilter,
      isFreeCancellation,
      isPickupIncluded,
    };
  }

  public updateActivities() {
    this.isLoading = true;

    this.destroy$.next();

    this.activitiesApiService
      .searchActivities({
        limit: this.limit,
        page: this.page,
        sortBy: this.sortBy,
        ...this.getFilters(),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.activities = data.items;
          this.totalItems = data.totalItems;
          this.totalFilteredItems = data.totalFilteredItems;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }
}
