import { RouteDto } from '../core/api/generated/riyado-api';
import { routeTypes } from './route.types';

export function getTypeName(type: RouteDto['type']): string {
  return routeTypes[type]?.name || '';
}

export function getTypeIcon(type: RouteDto['type']): string {
  return routeTypes[type]?.icon || '';
}
