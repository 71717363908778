/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getTripadvisorReviews } from '../fn/reviews/get-tripadvisor-reviews';
import { GetTripadvisorReviews$Params } from '../fn/reviews/get-tripadvisor-reviews';
import { TripAdvisorReviewsListDto } from '../models/trip-advisor-reviews-list-dto';

@Injectable({ providedIn: 'root' })
export class ReviewsApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTripadvisorReviews()` */
  static readonly GetTripadvisorReviewsPath = '/api/v1/reviews/tripadvisor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTripadvisorReviews()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTripadvisorReviews$Response(params: GetTripadvisorReviews$Params, context?: HttpContext): Observable<StrictHttpResponse<TripAdvisorReviewsListDto>> {
    return getTripadvisorReviews(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTripadvisorReviews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTripadvisorReviews(params: GetTripadvisorReviews$Params, context?: HttpContext): Observable<TripAdvisorReviewsListDto> {
    return this.getTripadvisorReviews$Response(params, context).pipe(
      map((r: StrictHttpResponse<TripAdvisorReviewsListDto>): TripAdvisorReviewsListDto => r.body)
    );
  }

}
