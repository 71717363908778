<div class="filter-slider">
  <div class="title-block">
    <div class="title">{{ title }}</div>
    <div class="clear" (click)="clear()">clear</div>
  </div>

  <div class="slider" *ngIf="apiHelper.isBrowser">
    <ngx-slider
      [options]="{
        floor: min,
        ceil: max,
        translate: translate.bind(this),
        step
      }"
      [(value)]="valueMin"
      [(highValue)]="valueMax"
      (userChangeEnd)="onChange()"></ngx-slider>
  </div>
</div>
