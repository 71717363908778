import { Component, Input, ViewChild } from '@angular/core';
import mapboxgl, { LngLatLike } from 'mapbox-gl';
import {
  formatDistance,
  formatMinutes,
  getMainPictureUrl,
} from '../../places/places.utils';
import { MapboxComponent, MapCoordinates } from '../mapbox/mapbox.component';
import { PlacePreviewDto, RoutePreviewDto } from '../api/generated/riyado-api';
import { placeActivities } from '../../places/places.types';
import {
  matHourglassTopOutline,
  matPaidOutline,
  matRouteOutline,
} from '@ng-icons/material-icons/outline';
import { getTypeName } from '../../routes/routes.utils';
import { CurrencyManager } from '../currency-manager';

export interface IMapItem {
  name: string;
  url: string;
  index: string;
  thumbUrl: string;
  highlight?: boolean;
  photoUrl: string;
  labels: string[];
  distance?: number;
  routeDistance?: number;
  duration?: number;
  minPrice?: number;
  location: {
    x: number;
    y: number;
  };
}

@Component({
  selector: 'app-places-map',
  templateUrl: './places-map.component.html',
  styleUrls: ['./places-map.component.scss'],
})
export class PlacesMapComponent {
  @ViewChild(MapboxComponent)
  public mapboxElement: MapboxComponent;

  @Input()
  center?: MapCoordinates;

  @Input()
  loading = false;

  @Input()
  googleMapLink?: string;

  public items: IMapItem[] = [];

  private popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    focusAfterOpen: false,
    className: 'preview-popup',
  });
  private itemMetaMap: Record<
    string,
    { html: string; coordinates: LngLatLike; url: string }
  > = {};

  private popupCloseTimer: number = 0;
  private popupOpenTimer: number = 0;

  private mapSourceId = 'places';
  private mapLayerId = 'places';

  constructor(public readonly currencyManager: CurrencyManager) {}

  public cleanMap() {
    this.itemMetaMap = {};

    const map = this.mapboxElement.map;
    if (map && map.getSource(this.mapSourceId)) {
      map.removeLayer(this.mapLayerId);
      map.removeSource(this.mapSourceId);
    }
  }

  public renderPlaces(
    places: { data: PlacePreviewDto; distance?: number; highlight?: boolean }[],
  ) {
    this.items = [];

    for (const place of places) {
      const p = place.data;

      this.items.push({
        name: p.name,
        index: p.index,
        url: `/places/${p.index}`,
        thumbUrl: getMainPictureUrl([p.preview], 'thumb'),
        photoUrl: getMainPictureUrl([p.preview], 'small'),
        labels: p.activities.map((a) => placeActivities[a].name),
        distance: place.distance,
        highlight: place.highlight,
        duration: p.suggestedVisitDuration,
        minPrice: p.viatorFromPriceAed || p.minPrice,
        location: p.location,
      });
    }

    return this.renderMap();
  }

  public renderRoutes(
    routes: { data: RoutePreviewDto; distance?: number; highlight?: boolean }[],
  ) {
    this.items = [];

    for (const route of routes) {
      const r = route.data;

      this.items.push({
        name: r.name,
        index: r.index,
        url: `/routes/${r.index}`,
        thumbUrl: getMainPictureUrl([r.preview], 'thumb'),
        photoUrl: getMainPictureUrl([r.preview], 'small'),
        labels: [getTypeName(r.type)],
        distance: route.distance,
        highlight: route.highlight,
        duration: r.duration,
        routeDistance: r.distance,
        location: r.location,
      });
    }

    return this.renderMap();
  }

  private renderMap() {
    const map = this.mapboxElement.map;

    if (!map) {
      return;
    }

    this.mapboxElement.onMapLoad(() => {
      this.cleanMap();
      this.mapboxElement.resize();

      for (const item of this.items) {
        const coordinates = {
          lng: item.location.x,
          lat: item.location.y,
        };

        // Create a HTML element for each marker
        const markerElement = document.createElement('div');
        markerElement.className = 'custom-marker';
        markerElement.style.backgroundImage = `url(${item.thumbUrl})`;
        markerElement.style.width = '50px'; // Adjust the size as needed
        markerElement.style.height = '50px';
        markerElement.style.borderRadius = '50%'; // Make it circular
        markerElement.style.border = '2px solid white';
        markerElement.style.backgroundSize = 'cover';
        markerElement.style.backgroundPosition = 'center';
        markerElement.style.zIndex = '1';
        if (item.highlight) {
          markerElement.style.border = '5px solid #ffb600';
          markerElement.style.zIndex = '5';
        }

        markerElement.addEventListener('mouseenter', () => {
          if (!item.highlight) {
            markerElement.style.zIndex = '3';
          }
        });

        markerElement.addEventListener('mouseout', () => {
          if (!item.highlight) {
            markerElement.style.zIndex = '1';
          }
        });

        markerElement.addEventListener('click', (e) => {
          e.stopPropagation();
          showPlace(item.index);
        });

        const html = `
          <div class="item-marker">
            <div class="content">
                <div class="img">
                   <img src="${item.photoUrl}" />
                </div>

                <div class="info">
                    <div class="title-block">
                        <a href="${item.url}" class="title-value">${item.name}</a>
                        ${
                          item.distance
                            ? `
                         <div class="distance">
                            <span class="dot"></span>
                            <span class="value">${formatDistance(item.distance)}</span>
                          </div>
                        `
                            : ''
                        }
                    </div>

                     <div class="labels">
                        ${item.labels
                          .sort((a, b) => a.length - b.length)
                          .slice(0, 3)
                          .map(
                            (a) => `
                            <div class="label">
                                <span>${a}</span>
                             </div>`,
                          )
                          .join('')}
                    </div>

                     <div class="short-info">
                     ${
                       item.duration
                         ? `
                       <div class="info-item">
                        ${matHourglassTopOutline}
                        ${formatMinutes(item.duration)}
                      </div>
                     `
                         : ''
                     }
                      ${
                        item.minPrice
                          ? `
                           <div class="info-item">
                            ${matPaidOutline}
                            from ${this.currencyManager.format(item.minPrice)}
                          </div>
                         `
                          : ''
                      }
                       ${
                         item.routeDistance
                           ? `
                             <div class="info-item">
                              ${matRouteOutline}
                              ${formatDistance(item.routeDistance)}
                            </div>
                           `
                           : ''
                       }
                    </div>

                </div>
            </div>
          </div>`;

        this.itemMetaMap[item.index] = {
          html,
          coordinates,
          url: item.url,
        };

        new mapboxgl.Marker(markerElement).setLngLat(coordinates).addTo(map);
      }

      const showPlace = (index: string) => {
        this.closePopup();
        const item = this.itemMetaMap[index];

        if (item) {
          this.openPopup(index, item.coordinates);
        }
      };

      map.on('click', () => {
        this.closePopup();
      });
    });
  }

  public moveMapToItem(index: string, isOpenPopup = false) {
    const map = this.mapboxElement.map;
    const meta = this.itemMetaMap[index];

    if (!meta || !map) {
      return;
    }

    const targetPoint = map.project(meta.coordinates);

    const adjustedPoint = {
      x: targetPoint.x,
      y: targetPoint.y + map.getContainer().clientHeight / 2 - 50,
    };

    const adjustedLngLat = map.unproject(<any>adjustedPoint);

    map.easeTo({
      center: adjustedLngLat,
      duration: 1000,
      essential: true,
    });

    if (isOpenPopup) {
      clearTimeout(this.popupOpenTimer);
      this.popupOpenTimer = <any>setTimeout(() => {
        this.openPopup(index);
      }, 500);
    }
  }

  public openPopup(index: string, coordinates?: LngLatLike) {
    const map = this.mapboxElement.map;
    const meta = this.itemMetaMap[index];

    if (!meta || !map) {
      return;
    }

    this.popup
      .setHTML(meta.html)
      .setLngLat(coordinates || meta.coordinates)
      .setOffset({
        top: [0, 25],
        'bottom': [0, -25],
      })
      .addTo(map);
  }

  public closePopup() {
    clearTimeout(this.popupCloseTimer);
    clearTimeout(this.popupOpenTimer);

    this.popup.remove();
  }
}
