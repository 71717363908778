import { Component, Input } from '@angular/core';
import { PlacePreviewDto } from '../../core/api/generated/riyado-api';
import {
  formatClosedType,
  formatMinutes,
  getActivityIcon,
  getActivityName,
  getMainPictureUrl,
  removeHtmlTags,
} from '../places.utils';
import { CurrencyManager } from '../../core/currency-manager';

@Component({
  selector: 'app-place-preview',
  styleUrls: ['place-preview.component.scss'],
  templateUrl: 'place-preview.component.html',
})
export class PlacePreviewComponent {
  @Input()
  public place: PlacePreviewDto;

  @Input()
  public horizontal = false;

  @Input()
  public newTab = false;

  protected readonly getActivityName = getActivityName;
  protected readonly formatMinutes = formatMinutes;
  protected readonly getMainPictureUrl = getMainPictureUrl;

  getPlaceActivities() {
    return this.horizontal
      ? this.place.activities
      : this.place.activities.slice(0, 3);
  }

  protected readonly getActivityIcon = getActivityIcon;
  protected readonly removeHtmlTags = removeHtmlTags;

  constructor(public readonly currencyManager: CurrencyManager) {}

  protected readonly formatClosedType = formatClosedType;
}
