import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import {
  NearestRouteDto,
  PlacePreviewDto,
  PlacesApiService,
  RegionDto,
  RegionsApiService,
  RouteDto,
  RoutesApiService,
  RouteStepDto,
} from '../../core/api/generated/riyado-api';
import { ActivatedRoute } from '@angular/router';
import { IGalleryFile } from '../../core/gallery/gallery.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { MapboxComponent } from '../../core/mapbox/mapbox.component';
import { LngLatLike, Marker } from 'mapbox-gl';
import {
  formatDistance,
  formatMinutes,
  getMainPictureUrl,
} from '../../places/places.utils';
import { getTypeIcon, getTypeName } from '../routes.utils';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';
import { Meta, Title } from '@angular/platform-browser';
import { ApiHelper } from '../../core/api/api.helper';

dayjs.extend(utc);

@Component({
  selector: 'app-route-full-page',
  styleUrls: ['route-full-page.component.scss'],
  templateUrl: 'route-full-page.component.html',
})
export class RouteFullPageComponent {
  public route?: RouteDto | null;
  public places: PlacePreviewDto[] = [];
  public placesMap?: Map<number, PlacePreviewDto>;
  public nearestRoutes?: NearestRouteDto[];
  public region?: RegionDto;

  @ViewChild(MapboxComponent)
  private mapComponent: MapboxComponent;

  private markers: Marker[] = [];
  private mapSourceId = 'route';
  private mapLayerId = 'route-full-page-line';

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly routesApiService: RoutesApiService,
    private readonly regionsApiService: RegionsApiService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta,
    private apiHelper: ApiHelper,
  ) {
    titleService.setTitle('Riyado - Route Information');
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.route || !this.region) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: this.region.name,
        url: `/regions/${this.region.index}`,
      },
      {
        label: 'Routes',
        url: `/routes`,
        qs: {
          regions: [this.region.id],
        },
      },
      {
        label: this.route.name,
        url: `/routes/${this.route.index}`,
      },
    ];
  }

  get media(): IGalleryFile[] | undefined {
    return this.route?.files.map((f) => ({
      url: f.originalUrl,
      mediumUrl: f.mediumSizeUrl,
      thumbUrl: f.smallSizeUrl,
    }));
  }

  ngOnInit(): void {
    this.updateData();

    if (this.apiHelper.isBrowser) {
      this.activatedRoute.paramMap.subscribe(() => {
        this.updateData();
      });
    }
  }

  getStepPlaces(step: RouteStepDto): PlacePreviewDto[] {
    const places = [];

    for (const id of step.placeIds) {
      const foundPlace = this.placesMap?.get(id);

      if (foundPlace) {
        places.push(foundPlace);
      }
    }

    return places;
  }

  private generatePageMeta(data: RouteDto) {
    const title = `Riyado - ${data.name}`;

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: getMainPictureUrl(data.files, 'small'),
    });

    if (data.seoDescription) {
      this.metaService.updateTag({
        name: 'description',
        content: data.seoDescription,
      });
      this.metaService.updateTag({
        name: 'og:description',
        content: data.seoDescription,
      });
    }

    if (data.seoKeywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: data.seoKeywords,
      });
    }
  }

  public updateData() {
    const params = this.activatedRoute.snapshot.paramMap;

    const index = String(params.get('index'));

    this.routesApiService.getRouteByIndex({ index }).subscribe({
      error: (err) => {
        if (err && err.status === 404) {
          this.route = null;
        } else {
          throw err;
        }
      },
      next: (data) => {
        this.route = data;

        this.generatePageMeta(data);

        setTimeout(() => {
          this.setMapRoute(data.route.map((r) => [r.x, r.y]));
        }, 0);

        this.regionsApiService
          .getRegionById({ id: data.regionId })
          .subscribe((data) => {
            this.region = data;
            this.cdr.detectChanges();
          });

        this.placesApiService
          .getPlacesPreview({
            ids: data.placeIds,
          })
          .subscribe((data) => {
            this.placesMap = new Map(data.map((d) => [d.id, d]));
            this.places = data;
            this.cdr.detectChanges();
          });

        this.routesApiService
          .getNearestRoutes({ index: data.index, limit: 3 })
          .subscribe((data) => {
            this.nearestRoutes = data;
            this.cdr.detectChanges();

            setTimeout(() => {
              this.mapComponent.resize();
            }, 0);
          });
      },
    });
  }

  private cleanMap() {
    for (const m of this.markers) {
      m.remove();
    }

    const map = this.mapComponent.map;

    if (map && map.getSource(this.mapSourceId)) {
      map.removeLayer(this.mapLayerId);
      map.removeSource(this.mapSourceId);
    }
  }

  public setMapRoute(route: LngLatLike[]) {
    const map = this.mapComponent.map;

    if (!map) {
      return;
    }

    this.mapComponent.onMapLoad(() => {
      this.cleanMap();

      let index = 1;
      for (const p of route) {
        // Create a div element for the custom marker
        const el = document.createElement('div');
        el.className = 'route-marker';
        el.innerText = `${index++}`;

        el.style.borderRadius = '50%';
        el.style.backgroundColor = '#4264fb';
        el.style.border = '2px solid rgb(255 255 255)';
        el.style.width = '30px';
        el.style.color = 'white';
        el.style.height = '30px';
        el.style.textAlign = 'center';
        el.style.lineHeight = '25px';
        el.style.fontSize = '14px';
        el.style.fontWeight = '500';
        el.style.cursor = 'pointer';

        const marker = new Marker({
          element: el,
        });
        marker.setLngLat(p).addTo(map);
        this.markers.push(marker);
      }

      if (route.length > 1) {
        map.addSource(this.mapSourceId, {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: route.map((r) => Array.from(<any>r)),
            },
          },
        });

        map.addLayer({
          id: this.mapLayerId,
          type: 'line',
          source: this.mapSourceId,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#4264fb',
            'line-width': 6,
          },
        });
      }
    });
  }

  protected readonly getTypeIcon = getTypeIcon;
  protected readonly getTypeName = getTypeName;
  protected readonly formatMinutes = formatMinutes;
  protected readonly formatDistance = formatDistance;
}
