/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getConnectedToPlace } from '../fn/routes/get-connected-to-place';
import { GetConnectedToPlace$Params } from '../fn/routes/get-connected-to-place';
import { getNearestRoutes } from '../fn/routes/get-nearest-routes';
import { GetNearestRoutes$Params } from '../fn/routes/get-nearest-routes';
import { getRouteByIndex } from '../fn/routes/get-route-by-index';
import { GetRouteByIndex$Params } from '../fn/routes/get-route-by-index';
import { getRoutes } from '../fn/routes/get-routes';
import { GetRoutes$Params } from '../fn/routes/get-routes';
import { getRoutesPreview } from '../fn/routes/get-routes-preview';
import { GetRoutesPreview$Params } from '../fn/routes/get-routes-preview';
import { NearestRouteDto } from '../models/nearest-route-dto';
import { RouteDto } from '../models/route-dto';
import { RoutePreviewDto } from '../models/route-preview-dto';
import { RoutesPaginatedResponseDto } from '../models/routes-paginated-response-dto';
import { RoutesPreviewPaginatedResponseDto } from '../models/routes-preview-paginated-response-dto';
import { searchRoutes } from '../fn/routes/search-routes';
import { SearchRoutes$Params } from '../fn/routes/search-routes';
import { searchRoutesPreview } from '../fn/routes/search-routes-preview';
import { SearchRoutesPreview$Params } from '../fn/routes/search-routes-preview';

@Injectable({ providedIn: 'root' })
export class RoutesApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRoutes()` */
  static readonly GetRoutesPath = '/api/v1/routes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoutes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoutes$Response(params?: GetRoutes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RouteDto>>> {
    return getRoutes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoutes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoutes(params?: GetRoutes$Params, context?: HttpContext): Observable<Array<RouteDto>> {
    return this.getRoutes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RouteDto>>): Array<RouteDto> => r.body)
    );
  }

  /** Path part for operation `getRoutesPreview()` */
  static readonly GetRoutesPreviewPath = '/api/v1/routes/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoutesPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoutesPreview$Response(params?: GetRoutesPreview$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RoutePreviewDto>>> {
    return getRoutesPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoutesPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoutesPreview(params?: GetRoutesPreview$Params, context?: HttpContext): Observable<Array<RoutePreviewDto>> {
    return this.getRoutesPreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RoutePreviewDto>>): Array<RoutePreviewDto> => r.body)
    );
  }

  /** Path part for operation `searchRoutesPreview()` */
  static readonly SearchRoutesPreviewPath = '/api/v1/routes/search/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchRoutesPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchRoutesPreview$Response(params?: SearchRoutesPreview$Params, context?: HttpContext): Observable<StrictHttpResponse<RoutesPreviewPaginatedResponseDto>> {
    return searchRoutesPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchRoutesPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchRoutesPreview(params?: SearchRoutesPreview$Params, context?: HttpContext): Observable<RoutesPreviewPaginatedResponseDto> {
    return this.searchRoutesPreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoutesPreviewPaginatedResponseDto>): RoutesPreviewPaginatedResponseDto => r.body)
    );
  }

  /** Path part for operation `searchRoutes()` */
  static readonly SearchRoutesPath = '/api/v1/routes/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchRoutes()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchRoutes$Response(params?: SearchRoutes$Params, context?: HttpContext): Observable<StrictHttpResponse<RoutesPaginatedResponseDto>> {
    return searchRoutes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchRoutes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchRoutes(params?: SearchRoutes$Params, context?: HttpContext): Observable<RoutesPaginatedResponseDto> {
    return this.searchRoutes$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoutesPaginatedResponseDto>): RoutesPaginatedResponseDto => r.body)
    );
  }

  /** Path part for operation `getRouteByIndex()` */
  static readonly GetRouteByIndexPath = '/api/v1/routes/{index}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRouteByIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRouteByIndex$Response(params: GetRouteByIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<RouteDto>> {
    return getRouteByIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRouteByIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRouteByIndex(params: GetRouteByIndex$Params, context?: HttpContext): Observable<RouteDto> {
    return this.getRouteByIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<RouteDto>): RouteDto => r.body)
    );
  }

  /** Path part for operation `getNearestRoutes()` */
  static readonly GetNearestRoutesPath = '/api/v1/routes/nearest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNearestRoutes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNearestRoutes$Response(params: GetNearestRoutes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NearestRouteDto>>> {
    return getNearestRoutes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNearestRoutes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNearestRoutes(params: GetNearestRoutes$Params, context?: HttpContext): Observable<Array<NearestRouteDto>> {
    return this.getNearestRoutes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NearestRouteDto>>): Array<NearestRouteDto> => r.body)
    );
  }

  /** Path part for operation `getConnectedToPlace()` */
  static readonly GetConnectedToPlacePath = '/api/v1/routes/connectedToPlace/{placeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConnectedToPlace()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectedToPlace$Response(params: GetConnectedToPlace$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RoutePreviewDto>>> {
    return getConnectedToPlace(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConnectedToPlace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectedToPlace(params: GetConnectedToPlace$Params, context?: HttpContext): Observable<Array<RoutePreviewDto>> {
    return this.getConnectedToPlace$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RoutePreviewDto>>): Array<RoutePreviewDto> => r.body)
    );
  }

}
