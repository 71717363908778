<div class="place-near-by">
  <a class="photo" routerLink="/places/{{ place.index }}" target="_blank">
    <img
      [ngSrc]="getMainPictureUrl([place.preview], 'thumb')"
      fill
      alt="{{ place.name }}" />
  </a>

  <div class="info">
    <div class="title-block">
      <a
        class="title-value"
        routerLink="/places/{{ place.index }}"
        target="_blank">
        <h4>{{ place.name }}</h4>
      </a>
      <div class="distance" *ngIf="distance">
        <span class="dot"></span>
        <span class="value">{{ formatDistance(distance) }}</span>
      </div>
    </div>

    <div class="rating-block">
      <div class="rating" *ngIf="place.tripadvisorData || place.googleData">
        <app-google-rating
          *ngIf="place.googleData && !place.tripadvisorData"
          [small]="true"
          [data]="place.googleData"></app-google-rating>

        <app-tripadvisor-rating
          *ngIf="place.tripadvisorData"
          [data]="place.tripadvisorData!"
          [small]="true"></app-tripadvisor-rating>
      </div>

      <div class="activities" *ngIf="place?.activities">
        <div
          class="activity"
          *ngFor="let a of getPlaceActivities()"
          [title]="getActivityName(a)">
          {{ getActivityName(a) }}
        </div>
      </div>
    </div>
  </div>
</div>
