import { isUndefined } from 'lodash';
import dayjs from 'dayjs';
import {
  ActivityItineraryDurationDto,
  FileDto,
  PlaceDailyOpenHoursDto,
  PlaceDto,
  PlacePreviewDto,
  PlaceWeeklyOpenHoursDto,
} from '../core/api/generated/riyado-api';
import { placeActivities } from './places.types';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import pluralize from 'pluralize';

dayjs.extend(utc);
dayjs.extend(timezone);

export function formatMinutes(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours >= 0 && remainingMinutes === 0) {
    return pluralize('hour', hours, true);
  }

  let result = hours > 0 ? `${hours}h` : '';
  if (remainingMinutes > 0) {
    result += result
      ? ` ${remainingMinutes}m`
      : pluralize('minute', remainingMinutes, true);
  }

  return result;
}

export function formatActivityDuration(duration: ActivityItineraryDurationDto) {
  if (duration.unstructuredDuration) {
    return duration.unstructuredDuration;
  }

  if (duration.fixedDurationInMinutes) {
    return formatMinutes(duration.fixedDurationInMinutes);
  }

  if (
    duration.variableDurationFromMinutes &&
    duration.variableDurationToMinutes
  ) {
    return `${formatMinutes(duration.variableDurationFromMinutes)} - ${formatMinutes(duration.variableDurationToMinutes)}`;
  }

  return '';
}

export function isOpenNow(time: PlaceDailyOpenHoursDto, tz: string) {
  if (
    !time.isOpen ||
    isUndefined(time.openTime) ||
    isUndefined(time.closeTime)
  ) {
    return false;
  }

  if (time.isOpen24h) {
    return true;
  }

  const currentTime = dayjs().tz(tz);
  const start = dayjs.tz(
    `${currentTime.format('YYYY-MM-DD')} ${time.openTime}`,
    'YYYY-MM-DD HH:mm',
    tz,
  );
  const end = dayjs.tz(
    `${currentTime.format('YYYY-MM-DD')} ${time.closeTime}`,
    'YYYY-MM-DD HH:mm',
    tz,
  );

  // Handle overnight times (closing past midnight)
  if (end.isBefore(start)) {
    // If current time is before end time, it means the place is open overnight
    return currentTime.isAfter(start) || currentTime.isBefore(end);
  }

  // Compare current time with start and end times
  return currentTime.isAfter(start) && currentTime.isBefore(end);
}

export function getCurrentWorkingTime(workingTime?: PlaceWeeklyOpenHoursDto) {
  const currentDay: keyof PlaceWeeklyOpenHoursDto = <
    keyof PlaceWeeklyOpenHoursDto
  >dayjs().format('dddd').toLowerCase();

  if (!workingTime || !workingTime[currentDay]) {
    return {
      isOpen: false,
      isOpen24h: false,
    };
  }

  return workingTime[currentDay];
}

export function getActivityName(
  activity: PlaceDto['activities'][number],
): string {
  return placeActivities[activity]?.name || '';
}

export function getActivityIcon(
  activity: PlaceDto['activities'][number],
): string {
  return placeActivities[activity]?.icon || '';
}

export function formatDistance(distanceVal?: number): string {
  const distance = distanceVal || 0;

  return `${(distance / 1000).toFixed(1)}km`;
}

export function getMainPictureUrl(
  files?: (FileDto | undefined)[],
  size?: 'medium' | 'thumb' | 'small',
): string {
  let key: keyof FileDto = 'mediumSizeUrl';

  if (size === 'thumb') {
    key = 'thumbSizeUrl';
  }

  if (size === 'small') {
    key = 'smallSizeUrl';
  }

  return files && files[0] ? files[0][key] : '/assets/stub.jpg';
}

export function formatWorkingTime(time: PlaceDailyOpenHoursDto): string {
  if (
    !time.isOpen ||
    isUndefined(time.openTime) ||
    isUndefined(time.closeTime)
  ) {
    return 'Closed';
  } else {
    if (time.isOpen24h) {
      return 'Open 24h';
    }

    return `${time.openTime}-${time.closeTime}`;
  }
}

export function removeHtmlTags(str: string) {
  return str.replace(/<\/?[^>]+(>|$)/g, '');
}

export function formatClosedType(place: PlaceDto | PlacePreviewDto): string {
  let closedPlaceDescription: string;

  switch (place.closed) {
    case 'TemporarilyClosed':
      closedPlaceDescription = 'Place temporarily closed';
      break;
    case 'PartiallyClosed':
      closedPlaceDescription = 'Place partially closed';
      break;
    case 'Closed':
    default:
      closedPlaceDescription = 'Place closed';
  }

  if (place.closedSeasons && place.closedSeasons.length > 0) {
    closedPlaceDescription += ' for ' + place.closedSeasons.join(', ');
  }

  return closedPlaceDescription;
}
