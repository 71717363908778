import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiHelper } from './api/api.helper';

@Injectable({ providedIn: 'root' })
export class CurrencyManager {
  private currencySubject = new BehaviorSubject<string>(
    this.getStoredCurrency(),
  );
  currentCurrency$ = this.currencySubject.asObservable();

  constructor(private readonly apiHelper: ApiHelper) {}

  setCurrency(currency: string) {
    localStorage.setItem('currentCurrency', currency);
    this.currencySubject.next(currency);
  }

  getCurrency(): string {
    return this.currencySubject.value;
  }

  getCurrencyWithSymbol(): string {
    const currency = this.getCurrency();
    let symbol = '';

    switch (currency) {
      case 'USD':
        symbol = '$';
        break;
      case 'AED':
        symbol = 'د.إ';
        break;
    }

    return `${currency} ${symbol}`;
  }

  convertCurrency(
    value: number,
    currency: string = 'AED',
    targetCurrency?: string,
  ): number {
    const currentCurrency = targetCurrency || this.getCurrency();

    switch (currentCurrency) {
      case 'USD':
        return currency === 'AED' ? Math.ceil(value / 3.67) : value;

      case 'AED':
        return currency === 'USD' ? Math.ceil(value * 3.67) : value;
    }

    return value;
  }

  toggleCurrency(): string {
    const val = this.getCurrency();
    const newVal = val === 'AED' ? 'USD' : 'AED';
    this.setCurrency(newVal);

    return newVal;
  }

  private getStoredCurrency(): string {
    if (this.apiHelper.isBrowser) {
      return localStorage.getItem('currentCurrency') || 'USD';
    } else {
      return 'USD';
    }
  }

  public format(
    value?: number,
    currency: string = 'AED',
    targetCurrency?: string,
  ) {
    const currentCurrency = targetCurrency || this.getCurrency();

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currentCurrency,
      maximumFractionDigits: 0,
    });

    if (value !== undefined) {
      return formatter.format(
        this.convertCurrency(value, currency, targetCurrency),
      );
    }

    return '';
  }
}
