export enum OpeningHoursType {
  OpenNow = 1,
  Morning,
  Afternoon,
  Evening,
  Night,
}

export const placeActivities = {
  Cultural: {
    name: 'Cultural',
    icon: 'matMuseumOutline',
  },
  Shopping: {
    name: 'Shopping',
    icon: 'matLocalMallOutline',
  },
  Entertainment: {
    name: 'Entertainment',
    icon: 'matLiveTvOutline',
  },
  Natural: {
    name: 'Natural',
    icon: 'matNaturePeopleOutline',
  },
  Beaches: {
    name: 'Beaches',
    icon: 'matPoolOutline',
  },
  Family: {
    name: 'Family',
    icon: 'matFamilyRestroomOutline',
  },
  Trekking: {
    name: 'Trekking',
    icon: 'matHikingOutline',
  },
  Cycling: {
    name: 'Cycling',
    icon: 'matDirectionsBikeOutline',
  },
  Camping: {
    name: 'Camping',
    icon: 'matHolidayVillageOutline',
  },
  Extrime: {
    name: 'Extrime',
    icon: 'matSnowboardingOutline',
  },
  Photo: {
    name: 'Photo',
    icon: 'matPhotoCameraOutline',
  },
  IconicBuilding: {
    name: 'Iconic buildings',
    icon: 'bootstrapBuildingsFill',
  },
  Walking: {
    name: 'Walk',
    icon: 'bootstrapPersonWalking',
  },
  ViewPoint: {
    name: 'View point',
    icon: 'bootstrapTreeFill',
  },
  WaterActivity: {
    name: 'Water activity',
    icon: 'bootstrapWater',
  },
};
