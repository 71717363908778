/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ActivitiesPaginatedResponseDto } from '../../models/activities-paginated-response-dto';

export interface SearchActivities$Params {
  minMaxDuration?: Array<number>;
  minMaxPrice?: Array<number>;
  limit?: number;
  page?: number;
  sortBy?: 'Title' | 'Rating' | 'PriceLow' | 'PriceHigh' | 'Random';
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  ratings?: Array<number>;
  isFreeCancellation?: boolean;
  isPickupIncluded?: boolean;
  productCodes?: Array<string>;
}

export function searchActivities(http: HttpClient, rootUrl: string, params?: SearchActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivitiesPaginatedResponseDto>> {
  const rb = new RequestBuilder(rootUrl, searchActivities.PATH, 'get');
  if (params) {
    rb.query('minMaxDuration', params.minMaxDuration, {});
    rb.query('minMaxPrice', params.minMaxPrice, {});
    rb.query('limit', params.limit, {});
    rb.query('page', params.page, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('startDate', params.startDate, {});
    rb.query('endDate', params.endDate, {});
    rb.query('startTime', params.startTime, {});
    rb.query('endTime', params.endTime, {});
    rb.query('ratings', params.ratings, {});
    rb.query('isFreeCancellation', params.isFreeCancellation, {});
    rb.query('isPickupIncluded', params.isPickupIncluded, {});
    rb.query('productCodes', params.productCodes, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ActivitiesPaginatedResponseDto>;
    })
  );
}

searchActivities.PATH = '/api/v1/activities/search';
