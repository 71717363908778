<div class="loader" [class.skeleton-loader]="!animation">
  <ng-lottie
    *ngIf="animation && apiHelper.isBrowser"
    [width]="width || '250px'"
    [height]="height || 'auto'"
    containerClass="loading-box"
    [options]="options" />

  <ngx-skeleton-loader
    *ngIf="!animation"
    [theme]="{ height: height || '50px', width, marginTop }"
    appearance="line"
    [count]="count || 1" />
</div>
